/**
 * Buttons
 **/

.button.donate {
	background: $dark-blue;
	color: $white;
	letter-spacing: 0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	padding: 0.5rem 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 161px;
	position: relative;
	height: 38px;
	border: none;
	white-space: nowrap;
	font-family: $header-font-family !important;
	border-radius: 5px 5px 25px 5px;

	span {
		padding-top: 3px;
	}
	svg {
		margin-right: 0.5rem;
		height: 18.21px;
		overflow: visible;
		path {
			@include transition;
		}
	}
	&:hover {
		color: $white;
		svg {
			path {
				fill: $white;
				&.heart {
					transform: translateY(-3px);
				}
			}
		}
	}
}

.button {
	background: transparent;
	border: 3px solid $dark-tan;
	color: $black;
	letter-spacing: 0;
	text-transform: uppercase;
	font-weight: 700;
	font-size: rem-calc(14);
	padding: 0.5rem 1.5rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: relative;
	display: inline-block;
	width: 100%;
	max-width: fit-content;
	font-family: $body-font-name !important;
	@include transition;

	&:hover {
		color: $black;
	}
	.wp-block-button__link {
		background: none;
		border: none;
		padding: .25rem;

		&:after, &:hover, &:hover:after {
			background: none;
			border: none;
			border-bottom: none;
			padding: .25rem;
		}
	}
}

.link,
.wp-block-button__link {
	color: $gray !important;
	border-bottom: 2px solid $dark-tan;
	border-radius: 0;
	position: relative;
	padding-right: 1rem;
	font-weight: 700;
	padding-left: 0;
	font-family: $body-font-family;
	padding-bottom: 0.25rem;
	background: none;
	padding-top: 0;

	&:after {
		content: " ";
		background: url(../images/right-chevron.svg);
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 8px;
		height: 13px;
	}

	&:hover {
		color: $gray;
		&:after {
			background: url(../images/right-chevron-light.svg);
		}
		padding-right: 1.25rem;
		border-bottom: 2px solid $light-tan;
	}
}

// gold-btn
.gold-btn-ctn{
	display: flex;
	align-items: center;
	gap: 5px;
	span{
		color: $gray;
		font-family: $header-font-family;
		font-size: 14px;
		font-weight: 700;
		line-height: normal;
		background-color: $light-tan;
		padding: 10px 16px 8px;
		border-radius: 40px;
		display: inline-block;
		transition: all .3s ease;
	}
	&:hover{
		span{
			background-color: $dark-blue;
			color: $white;
		}
		svg{
			transition: all .3s ease;
			circle{
				fill: $dark-blue;
			}
			path{
				fill: $white;
			}
		}
	}
}

.btn-ps-wavr{
	font-family: $header-font-family;
	font-size: 14px;
	font-weight: 700;
	line-height: normal;
	border-radius: 5px 5px 25px 5px;
	border: 2px solid $gray;
	color: $gray;
	min-width: 160px;
	width: 100%;
	display: inline-flex;
	max-width: fit-content;
	padding: 13px 10px 10px 16px;
	transition: all 0.3s ease;
	align-items: center;
	&:hover{
		background-color: $gray;
		color: $white;
	}
}