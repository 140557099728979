$white: #ffffff;
$black: #191919;
$gray: #5a5a5a;
$light-gray: #ebebeb;
$med-gray: #707070;
$gray-light: #bababa;

$dark-tan: #a99969;
$light-tan: #dbc992;
$blue: #334aff;
$dark-blue: #2A3352;
$mongoose: #A99768;

$primary-color: $dark-tan;
$secondary-color: $light-tan;
$tertiary-color: $blue;
$quaternary-color: $light-gray;
