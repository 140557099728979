.event-card {
	h3 {
		color: $med-gray;
		text-transform: uppercase;
		@include fluid-prop(font-size, 16px, 18px);
		margin-top: 1rem;
		margin-bottom: 0;
	}
	.card-date {
		color: $dark-tan;
		font-weight: bold;
	}
	.image {
		position: relative;

		img {
			object-fit: cover;
			object-position: top center;
			height: 195px;
			width: 100%;
			@include breakpoint(medium) {
				height: 237px;
			}
			@include breakpoint(large) {
				height: 279px;
			}
		}
		span {
			@include transition();
			position: absolute;
			opacity: 0;
			bottom: 0;
			left: 1rem;
			color: $white;
			font-weight: 700;
			background-image: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.6) 0%,
				rgba(0, 0, 0, 0.4) 40%,
				rgba(0, 0, 0, 0) 60%
			);
			width: 100%;
			left: 0;
			padding-bottom: 1rem;
			height: 125px;
			span {
				border-bottom: 2px solid $dark-tan;
				padding-left: 0;
				padding-right: 1.25rem;
				padding-bottom: 0.25rem;
				position: relative;
				top: 75px;
				left: 1rem;
				background: none;
				opacity: 1;
				&:after {
					content: " ";
					background: url(../images/right-chevron.svg);
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 8px;
					height: 13px;
				}
			}
		}

		&:hover span {
			opacity: 1;
		}

		@media (hover: none) {
			&:hover span {
				opacity: 0;
			}
		}
	}
}

.event-category-card {
	width: calc(50% - 0.27rem);
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	display: flex;

	&:nth-child(2n) {
		margin-right: 0;
	}

	@include breakpoint(1024px) {
		width: calc(33% - 1rem);

		&:nth-child(2n) {
			margin-right: 0.5rem;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}

	.image {
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 133px;

		@include breakpoint(1024px) {
			height: 264px;
		}

		h3 {
			color: $white;
			text-transform: uppercase;
			@include transition();
			@include fluid-prop(font-size, 21px, 32px);
			line-height: 1;
			font-family: $header-font-family;
		}
		.content {
			padding-left: 1rem;
			padding-bottom: 0.5rem;
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			@include transition();
			justify-content: flex-end;
			background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 50%);
		}
		span {
			position: absolute;
			@include transition();
			opacity: 0;
			color: $white;
			border-bottom: 2px solid $dark-tan;
			padding-bottom: 0.5rem;
			font-weight: 700;
			margin-bottom: 0.5rem;
		}

		&:hover {
			h3 {
				padding-bottom: 2rem;
				font-family: $header-font-name;
			}
			span {
				opacity: 1;
			}
		}

		@media (hover: none) {
			&:hover {
				h3 {
					padding-bottom: 0;
				}
				span {
					opacity: 0;
				}
			}
		}
	}
}

.tribe-events-calendar-list__event-datetime .tribe-event-date-start,
.single-tribe_events .tribe-blocks-editor .tribe-events-schedule .tribe-events-schedule__datetime,
.tribe-blocks-editor .tribe-related-event-info .timezone,
.tribe-blocks-editor .tribe-related-event-info .tribe-event-date-start,
.tribe-blocks-editor .tribe-related-event-info .tribe-event-time {
	color: $dark-tan;
	font-weight: bold;
}
.tribe-events-calendar-list__event-title,
.single-tribe_events .tribe-events-single-event-title,
.tribe-block__related-events__title,
.single-tribe_events ul.tribe-related-events .tribe-related-events-title {
	text-transform: uppercase;
}
.tribe-events .tribe-events-calendar-list__event-title-link,
.single-tribe_events .tribe-events-single-event-title,
.tribe-block__related-events__title,
.single-tribe_events ul.tribe-related-events .tribe-related-events-title {
	color: $med-gray !important;
}

.tribe-events-header__breadcrumbs {
	display: none;
}

.tribe-events .tribe-events-l-container {
	padding-top: 2rem !important;
}

#tribe-events-view-selector-content .tribe-events-c-view-selector__list {
	min-width: 110px;
}

.tribe-events-sub-nav li a {
	background: none;
	color: $primary-color !important;
	transition: all .3s ease;

	&:hover, &:focus {
		color: $primary-color-dark !important;
	}

	&:active {
		color: darken($primary-color-dark, 10%) !important;
	}

}

.events-wrapper{
	button.tribe-events-calendar-month__day-cell.tribe-events-calendar-month__day-cell--mobile.tribe-events-calendar-month__day-cell--selected{
		background-color: #969696 !important;
	}
	.tribe-events{
		.tribe-events-calendar-month__mobile-events-icon--event{
			background-color: $dark-tan !important;
		}
	}
	span.tribe-events-calendar-day__event-datetime-featured-text.tribe-common-a11y-visual-hide{
		color: $dark-tan !important;
	}
	.tribe-events .tribe-events-calendar-day__event--featured{
		&::after{
			background-color: $light-tan !important;
		}
	}
	.tribe-events-calendar-list__event-datetime-featured-text.tribe-common-a11y-visual-hide{
		color: $dark-tan !important;
	}
	.tribe-events-calendar-list__event-date-tag-datetime{
		&::after{
			background-color: $light-tan !important;
		}
	}
	.tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button{
		color: $gray;
		border: 1px solid $gray;
		font-family: $header-font-family;
		font-size: 13px;
		@include xbreakpoint(sm){
			border: 2px solid $gray;
			border-radius: 5px 5px 25px 5px;
			width: fit-content;
		}
		&:hover{
			background-color: $gray !important;
			svg{
				color: $white !important;
			}
		}
		svg{
			color: $gray !important;
		}
	}

	.tribe-events .tribe-events-calendar-month__calendar-event--featured{
		&:before{
			background-color: $gray !important;
		}
	}
	.tribe-common-h8{
		color: $gray !important; 
	}
	.tribe-events-calendar-month__multiday-event-bar{
		background-color: $light-tan !important;
	}
	.tribe-events-calendar-month__multiday-event-bar-inner{
		background-color: transparent !important;
	}
	.tribe-common .tribe-common-c-svgicon{
		color: $dark-tan !important;
	}
	input#tribe-events-events-bar-keyword{
		outline: none;
		font-family: $header-font-family;
		font-size: 12px;
		padding-top: 10px;
		padding-bottom: 16px;
	}
	.tribe-common-c-btn.tribe-events-c-search__button{
		color: $gray-light !important;
		font-family: $header-font-family !important;
		font-size: 12.044px !important;
		font-weight: 700 !important;
		line-height: 16px !important;
		background-color: transparent;
		border: 2px solid $gray-light !important;
		border-radius: 12px !important;
		padding: 8px 15px !important;
		&:hover{
			background-color: $gray-light !important;
			color: $white !important;
		} 
	}
	span.tribe-events-c-view-selector__list-item-text{
		color: $gray-light !important;
		font-family: $header-font-family !important;
		font-size: 12.044px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.tribe-events-c-view-selector__list-item-link{
		&::after{
			background-color: $gray-light !important;
		}
	}
	.tribe-events-calendar-month__calendar-event-featured-image-wrapper{
		display: none;
	}
}