// my header style
#topbar{
	background: $light-tan;
	@include xbreakpoint(md){
		min-height: 68px;
	}
	.container{
		max-width: 1180px;
		width: 100%;
		padding-left: 60px;
		margin: 0 auto;
		@include xbreakpoint(sm){
			padding-right: 10px;
			padding-left: 24px;
		}
		.row{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.col-left-wrapper{
				display: flex;
				flex-direction: row;
				width: 100%;
				#logo{
					img{
						max-width: 150px;
						width: 100%;
						margin-bottom: -70px;
						@include xbreakpoint(sm){
							max-width: 290px;
							margin-bottom: -170px;
						}
					}
				}
				.mega-close{
					left: inherit !important;
					right: 15px !important;
					top: 10px !important;
					&::before{
						font-size: 0 !important;
						background-image: url('../images/close-icon-pop.svg');
						background-repeat: no-repeat;
						background-position: right;
					}
				}
				.mega-menu-wrap{
					width: 100%;
					display: flex;
					align-items: center;
					.mega-menu-toggle{
						.mega-toggle-block{
							.mega-toggle-animated-inner{
								top: 22px;
								background-color: #5a5a5a !important;
								height: 2px;
								&::after{
									height: 2px;
									background-color: #5a5a5a !important;
								}
								&::before{
									height: 2px;
									background-color: #5a5a5a !important;
								}
							}
						}
					}
					#mega-menu-header-menu{
						width: 100%;
						margin-left: 35px;
						display: flex;
						flex-direction: row;
						column-gap: 25px;
						#searchButton{
							background-color: #A9976880;
							display: flex;
							flex-direction: row;
							align-items: center;
							min-width: 164px;
							justify-content: space-between;
							padding: 3px 9px;
							padding-left: 16px;
							min-height: 36px;
							max-width: 190px;
							margin-left: 40px;
							span{
								color: $gray;
								font-family: $header-font-family;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: normal;
								letter-spacing: 0.7px;
								text-transform: uppercase;
							}
						}
						.button.donate{
							width: 100%;
							max-width: 190px;
							margin-left: 40px;
							height: 44px;
							padding-top: 11px;
							margin-top: -20px;
						}
						.mega-menu-item {
							a{
								color: $gray;
								font-family: $header-font-family;
								font-size: 14px;
								font-weight: 700;
								line-height: normal;
								letter-spacing: 0.7px;
								text-transform: uppercase;
								padding: 0;
								@include xbreakpoint(lg){
									font-size: 16px;
									letter-spacing: 0.8px;
									padding-left: 50px;
									padding-right: 50px;
									.mega-indicator{
										float: inherit !important;
										&::after{
											transition: none !important;
										}
									}
								}
								&:hover{
									color: $dark-blue;
									.mega-indicator{
										&::after{
											transform: rotate(180deg) !important;
											@include xbreakpoint(lg){
												transform: none !important;
											}
										}
									}
								}
								.mega-indicator{
									&::after{
										top: -1px;
										transition: all .2s ease;
										left: -2px;
									}
								}
								@include xbreakpoint(xxl){
									font-size: 12px;
									height: inherit;
								}
							}
						}
						.mega-menu-item{
							.mega-sub-menu{
								&:nth-child(2){
									position: fixed !important;
									max-width: 922px !important;
									width: calc(100% - 25px);
									left: 50% !important;
									margin: 0 auto;
									transform: translateX(-50%) !important;
									top: 64px !important;
									background-color: $white !important;
									z-index: -1;
									border-bottom-right-radius: 30px;
									border: 2px solid $dark-tan;
									border-top: none;
									@include xbreakpoint(lg){
										position: relative !important;
										max-width: 100% !important;
										width: 100% !important;
										left: 0 !important;
										top: inherit !important;
										transform: none !important;
										margin-top: 20px;
										border-top: 2px solid $dark-tan;
									}
								}
								.mega-menu-row{
									>.mega-sub-menu{
										display: flex;
										align-items: center;
									}
								}
							}
						}
						.home-logo{
							display: none;
							@include xbreakpoint(lg){
								display: block;
								padding-left: 30px;
								padding-right: 40px;
							}
						}
						#searchButton{
							display: none;
							@include xbreakpoint(lg){
								display: flex;
							}
						}
						.button.donate{
							display: none;
							@include xbreakpoint(lg){
								display: block;
							}
						}
						.home-logo{
							img{
								max-width: 120px;
							}
						}
						@include xbreakpoint(xxl){
							column-gap: 8px;
						}
						@include xbreakpoint(lg){
							background-image: linear-gradient(#dbc992d9, #dbc992d9), url('../images/pop-head-bg.png');
							flex-direction: column;
							margin-left: 0;
							padding: 30px 0 30px;
							row-gap: 29px;
							li.mega-toggle-on{
								.mega-menu-link{
									.mega-indicator{
										&::after{
											content: "" !important;
											background-color: $gray!important;
											width: 14px !important;
											height: 2px !important;
											transition: none !important;
											top: 5px;
										}
									}
								}
							}
						}
					}
					@include xbreakpoint(lg){
						display: block;
					}
				}

			}
			.col-right{
				display: flex;
				flex-direction: row;
				align-items: center;
				@include xbreakpoint(lg){
					display: none;
				}
				.right{
					display: flex;
					flex-direction: row;
					width: max-content;
					#searchButton{
						background-color: #A9976880;
						height: 100%;
						flex-direction: row;
						align-items: center;
						min-width: 164px;
						width: 100%;
						justify-content: space-between;
						padding: 3px 9px;
						padding-left: 16px;
						display: flex;
						span{
							color: $gray;
							font-family: $header-font-family;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
							letter-spacing: 0.7px;
							text-transform: uppercase;
						}
						@include xbreakpoint(xxl){
							min-width: 125px;
						}
					}
					.button.donate{
						border-radius: 5px 5px 25px 5px;
						min-height: 36px;
						font-family: $header-font-family !important;
						min-width: 139px;
						text-transform: uppercase;
						font-size: 14px;
						display: flex;
						align-items: center;
						line-height: normal;
						justify-content: start;
						padding-left: 18px;
						margin: 0 5px 0 8px;
						span{
							padding-top: 3px;
							letter-spacing: 0 !important;
						}
					}
					.social{
						display: flex;
						align-items: center;
						margin-left: 10px;
					}
				}
			}
		}	
		@include xbreakpoint(xxl){
			padding-right: 35px;
			padding-left: 35px;
		}
	}
	&:hover{
		position: sticky;
		top: 0;
	}
}

// mega row
.mega-menu-item{
	.mega-menu-row{
		.mega-menu-columns-4-of-12{
			@include xbreakpoint(lg){
				display: none !important;
			}
			.mega-menu-item{
				position: relative;
				h4{
					position: absolute;
					bottom: -11px;
					left: 10px;
					color: $white !important;
					font-family: $header-font-family !important;
					font-size: 45px !important;
					font-weight: 700 !important;
					line-height: 45px;
					text-transform: uppercase !important;
					padding: 0 !important;
				}
				img{
					width: 100%;
					border-top-right-radius: 80px;
					min-height: 320px;
					object-fit: cover;
				}
			}
		}
		.mega-menu-columns-8-of-12{
			padding: 22px 20px 20px 44px !important;
			@include xbreakpoint(lg){
				padding: 22px 30px 20px 30px !important;
			}
			.mega-sub-menu{
				ul{
					display: flex;
					flex-wrap: wrap;
					padding: 0;
					list-style-type: none;
					justify-content: space-between;
					row-gap: 12px;
					@include xbreakpoint(lg){
						flex-direction: column;
					}
					li{
						width: 48.70%;
						@include xbreakpoint(lg){
							min-width: fit-content;
						}
						a{
							display: inline-block;
							width: 100%;
							color: $gray;
							font-family: $header-font-family;
							font-size: 16px !important;
							font-weight: 500 !important;
							line-height: normal !important;
							border-bottom: 1px solid $gray;
							padding-bottom: 7px !important; 
							text-transform: capitalize !important;
							height: 100%;
							@include xbreakpoint(lg){
								padding-left: 0 !important;
								padding-right: 0 !important;
							}
						}
					}
				}
				.widget_text{
					h4.mega-block-title{
						color: $gray !important;
						font-family: $header-font-family !important;
						font-size: 14px !important;
						font-style: normal;
						font-weight: 700 !important;
						line-height: normal;
						letter-spacing: 0.7px !important;
						text-transform: uppercase !important;
						padding: 0 !important;
						margin-bottom: 13px !important;
					}
				}
			}
		}
	}
}
// visit cs
.mega-visit-cs {
	.mega-menu-columns-8-of-12{
		.mega-sub-menu{
			li{
				&:first-child{
					ul{
						justify-content: start !important;
						gap: 12px !important; 
						li{
							width: auto !important;
							@include xbreakpoint(lg){
								width: 48% !important;
							}
							a{
								font-weight: 700 !important;
							}
						}
					}
				}
			}
			.widget_text{
				h4.mega-block-title{
					margin-top: 30px !important;
				}
			}
		}
	}
}
// header popup
.header-popup{
	display: none;
}
.header-popup.active{
	display: block;
}














// my header style ends


header#topbar {
	width: 100%;
	background: $light-tan;
	position: relative;
	z-index: 10;
	.inner {
		max-width: 1050px;
		width: 100%;
		margin: 0 auto;
		padding: 1rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 53px;
		position: relative;

		@include breakpoint(768px) {
			padding: 0.5rem 0;
		}

		#logo {
			width: 170px;
			display: block;
			position: absolute;
			transform: translateX(-50%);
			left: 10%;
			opacity: 1;
			transition: opacity 0.3s ease-in-out;

			a {
				position: absolute;
				top: 0.5rem;
			}
		}

		.left {
			padding: 0 1rem;

			a.social {
				margin-right: 0.5rem;
				display: none;

				@include breakpoint(768px) {
					display: inline;
				}

				svg {
					path {
						fill: $gray;
					}
				}
			}
			a#searchButton {
				text-transform: uppercase;
				align-items: center;
				font-size: 14px;
				font-weight: 700;
				color: $gray;
				display: inline-block;
				margin-left: 1rem;

				@include breakpoint(768px) {
					border-bottom: 2px solid $dark-tan;
				}

				span {
					margin-right: 0.5rem;
					margin-left: 0.2rem;
					color: #191919;
					letter-spacing: 1px;
					display: none;

					@include breakpoint(768px) {
						display: inline;
					}
				}
				svg {
					margin-right: 0.2rem;
					margin-bottom: 0.2rem;
				}
			}
		}
		.right {
			padding: 0 1rem;
			display: flex;
			flex-direction: row;
			align-content: center;
			align-items: center;
			min-width: auto;
			justify-content: flex-end;

			@include breakpoint(medium) {
				min-width: 287px;
			}

			.button {
				display: none;

				@include breakpoint(768px) {
					justify-content: space-between;
					display: flex;
				}
			}
		}
	}
}

#toggleMenu,
#closeMenu {
	border: none;
	background: none;
	cursor: pointer;
	margin-left: 2rem;
	width: 60px;
	margin-right: 5px;

	span {
		color: $black !important;
	}

	svg {
		height: 26px;
	}
}
#toggleMenu {
	svg#mobileHamburger {
		display: block;

		@include breakpoint(768px) {
			display: none;
		}
	}
	svg#hamburger {
		display: none;

		@include breakpoint(768px) {
			display: block;
		}
	}
}
#closeMenu {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: rem-calc(12px);
	display: none;
	padding-top: 1rem;
	@include breakpoint(768px) {
		padding-top: 0;
	}
}

#mainMenu {
	display: none;
	background: $light-tan;
	padding-top: 9rem;
	position: absolute;
	width: 100%;
	z-index: 1;
	top: 0;
	right: -800px;
	transition: right 0.3s ease-in-out;
	opacity: 1;
	max-width: 100%;
	overflow: hidden;

	@include breakpoint(768px) {
		transition: top 0.3s ease-in-out;
		top: -800px;
		right: 0;
		display:flex;
	}
	.inner {
		max-width: 1050px;
		width: 100%;
		margin: 0 auto;
		padding: 0.5rem 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		nav {
			width: 100%;
		}
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-around;
			width: 100%;

			li {
				margin-bottom: 1rem;

				.toggleMenuItem {
					display: none;
					background: none;
					border: none;
				}
				a {
					text-transform: uppercase;
					color: $black;
					font-weight: 500;
					font-size: rem-calc(20);
					@include transition();
					&:hover {
						color: lighten($black, 10%);
					}
				}
			}

			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: nowrap;
				margin-top: 1rem;

				li a {
					font-weight: 500;
					text-transform: none;
					color: $gray;
					font-size: rem-calc(16);

					&:hover {
						color: lighten($gray, 10%);
					}
				}
			}
		}
	}
}

#mobileDonor {
	display: none;
	width: 100%;
	max-width: 300px;
	margin: 0 auto 1rem;
	font-size: 1.2rem;
	height: auto;
	padding: .75rem 1.5rem;
}
body.active {
	#toggleMenu {
		display: none;
	}
	#closeMenu {
		display: flex;
	}
	#mainMenu {
		display:flex;
		opacity: 1;
		top: 0;
		right: 0;
		position: absolute;

		@include breakpoint(768px) {
			top: 53px;
			right: 0;
		}
	}
	@include breakpoint(767px, down) {
		header#topbar .inner #logo {
			display:none;
		}
		#mobileDonor {
			margin-top: 4.5rem;

			display: flex;
		}
	}
	header#topbar .inner .left a#searchButton span {
		display: inline;
	}
}

@include breakpoint(767px, down) {
	#mainMenu {
		display: flex;
		padding-top: 4rem;
		z-index: 5;
		position:fixed;

		.inner {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			ul {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				justify-content: center;
				width: 100%;

				li {
					margin-bottom: 1.5rem;
					width: 100%;
					border-bottom: 1px solid $dark-tan;
					padding-left: 2rem;
					padding-bottom: 2rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;
					align-content: center;
					font-weight: 500;
					position:relative;

					&:last-child {
						border: none;
					}

					.toggleMenuItem {
						display: flex;
						justify-content: center;
						flex-direction: column;
						background: none;
						border: none;
						position: absolute;
						right: 2rem;
						cursor: pointer;
						padding: 1rem;
						top: -1rem;
						z-index: 5;

						&:after {
							content: "";
							display: block;
							background: url(../images/plus.svg);
							height: 19px;
							width: 21px;
							cursor: pointer;
						}

						&.active:after {
							background: url(../images/minus.svg) no-repeat center center;
						}
					}
				}

				ul {
					display: none;
					flex-direction: column;
					padding-left: 0;

					li {
						border: none;
						padding: 0;
					}
				}

				li.active ul {
					display: flex;
					flex-direction: column;

					li {
						width: 100%;
					}
				}
			}
		}
	}
}
