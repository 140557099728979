@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poiret+One&display=swap');

:root {
	@include fluid-prop(--fluid-jumbo, 55px, 75px);
	@include fluid-prop(--fluid-h1, 35px, 45px);
	@include fluid-prop(--glob-h1, 41px, 50px);
	@include fluid-prop(--fluid-h2, 25px, 35px);
	@include fluid-prop(--glob-h2, 40px, 40px);
	@include fluid-prop(--fluid-h3, 20px, 30px);
	@include fluid-prop(--glob-h3, 24px, 24px);
	@include fluid-prop(--fluid-h4, 15px, 20px);
	@include fluid-prop(--glob-h4, 18px, 18px);
	@include fluid-prop(--fluid-h5, 14px, 18px);
	@include fluid-prop(--fluid-h6, 20px, 22px);
	@include fluid-prop(--fluid-body, 14px, 18px);
	@include fluid-prop(--fluid-body-sm, 14px, 18px);
	@include fluid-prop(--fluid-body-xs, 14px, 16px);
}

h1,
.fluid-h1 {
	font-size: var(--fluid-h1);
}

h1.global-cs {
	font-size: var(--glob-h1);
}

h2,
.fluid-h2 {
	font-size: var(--fluid-h2);
}

h2.global-cs {
	font-size: var(--glob-h2);
	color: $gray;
	font-weight: 500;
	line-height: normal;
}

h3,
.fluid-h3 {
	font-size: var(--fluid-h3);
}

h3.global-cs {
	font-size: var(--glob-h3);
}

h4,
.fluid-h4 {
	font-size: var(--fluid-h4);
}
h4.global-cs {
	font-size: var(--glob-h4);
	line-height: 24px;
	color: $gray;
	font-weight: 700;
}

h5,
.fluid-h5 {
	font-size: var(--fluid-h5);
}

h6,
.fluid-h6 {
	font-size: var(--fluid-h6);
}

html,
.fluid-body {
	font-size: var(--fluid-body);
}

.fluid-body-sm {
	font-size: var(--fluid-body-sm);
}
