@charset "UTF-8";
/**
 * Parent and Core Style Imports
 *
 * Imports all core mixins and all template/component styles
 *
 * DO NO EDIT OR REMOVE THESE DIRECTLY.
 *
 **/
/**
 * Parent and Core Style Imports
 *
 * Imports all core mixins and all template/component styles
 *
 * DO NOT EDIT OR REMOVE THESE DIRECTLY.
 *
 * Except for grids they can be commented out if they are not needed
 **/
/**
 * Settings for sizing variables
 **/
/**
 * Can be used in various places like fluid typography
 * Must be called from styles ex: padding: var(--fluid-padding)
 * Very useful when styles looks too large on mobile
 **/
/**
 * Simplified Breakpoint mixin
 * Is Mobile-First but includes the modifier key to go up/down
 *
 * //TODO `Only` modifier seems logically complex maybe we don't need it?
 **/
/**
 * Fluid properties, makes life so much easier responsiveness wise
 * BIG WARNING THIS ONLY OPERATES IN Pixel units
 **/
/**
 * Fluid Properties
 * Allows properties to be linearly fluid between two viewport values.
 * This is useful for a variety of typography and responsive sizing needs
 *
 * @group Settings Mixins
 * @param {property} $property - A css property
 * @param {pixel value} $min-size - The smallest size this value will shrink to on small viewports
 * @param {pixel value} $max-size - The largest size this value will stretch to on large viewports
 * @param {pixel value} $min-vw - OPTIONAL: can set the min viewport for custom styling needs
 * @param {pixel value} $max-vw - OPTIONAL: can set the max viewport for custom styling needs
 **/
/**
 * Styling needed for accessibility
 **/
/**
 * General variables that can be used throughout the theme :)
 **/
/**
 * --- Font-face setters ---
 * Supports Local and external font foundries
 * Prefer using .woff files when using local fonts
 **/
/** --- General typography settings --- **/
/**
 * General typography utilities
 **/
/*
@mixin general-typography-setter() {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: $header-font-weight;
		line-height: $header-line-height;
		margin-bottom: $header-margin-bottom;
		color: $header-color;
	}

	html {
		font-weight: $body-font-weight;
	}

	p {
		margin-bottom: $paragraph-margin-bottom;
	}
}*/
/**
 * --- Fluid Typography ---
 *
 * Creates fonts that will scale with the width of the screen using the function below
 * Works by using viewport width and some fancy linear algorithm that works with css calc!
 * Even IE11 works with this 🙌
 *
 * TODO Make this easier to edit
 **/
/** END --- Fluid Typography --- END **/
/** Blueprint css grid **/
/**
 * Simple rem calculator takes pixels and turns them into rem units
 **/
/**
 * General style normalizer
 * If sanitizer isn't working well for you there are various other options:
 * normalize.css, reset.css, css-mini-reset, html5 reset stylesheet etc...
 **/
/**
 * End Parent imports
 *
 **/
/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poiret+One&display=swap");
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct margin-top nobody ever wants margin-top
 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Default that images won't stretch outside of the parent container
 */
img {
  max-width: 100%;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
	  in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: montserrat, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: #a99969;
  text-transform: none;
}

html {
  font-weight: 500;
  font-family: acumin-pro, -apple-system, "Ubuntu", sans-serif;
  line-height: 1.2;
  color: #5a5a5a;
}

p {
  margin: 1rem 0;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

:root {
  --fluid-jumbo: 55px;
}
@media (min-width: 400px) {
  :root {
    --fluid-jumbo: calc(
					55px + 20 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-jumbo: 75px;
  }
}
:root {
  --fluid-h1: 35px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h1: calc(
					35px + 10 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h1: 45px;
  }
}
:root {
  --glob-h1: 41px;
}
@media (min-width: 400px) {
  :root {
    --glob-h1: calc(
					41px + 9 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --glob-h1: 50px;
  }
}
:root {
  --fluid-h2: 25px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h2: calc(
					25px + 10 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h2: 35px;
  }
}
:root {
  --glob-h2: 40px;
}
@media (min-width: 400px) {
  :root {
    --glob-h2: calc(
					40px + 0 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --glob-h2: 40px;
  }
}
:root {
  --fluid-h3: 20px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h3: calc(
					20px + 10 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h3: 30px;
  }
}
:root {
  --glob-h3: 24px;
}
@media (min-width: 400px) {
  :root {
    --glob-h3: calc(
					24px + 0 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --glob-h3: 24px;
  }
}
:root {
  --fluid-h4: 15px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h4: calc(
					15px + 5 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h4: 20px;
  }
}
:root {
  --glob-h4: 18px;
}
@media (min-width: 400px) {
  :root {
    --glob-h4: calc(
					18px + 0 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --glob-h4: 18px;
  }
}
:root {
  --fluid-h5: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h5: calc(
					14px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h5: 18px;
  }
}
:root {
  --fluid-h6: 20px;
}
@media (min-width: 400px) {
  :root {
    --fluid-h6: calc(
					20px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-h6: 22px;
  }
}
:root {
  --fluid-body: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body: calc(
					14px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body: 18px;
  }
}
:root {
  --fluid-body-sm: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-sm: calc(
					14px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-sm: 18px;
  }
}
:root {
  --fluid-body-xs: 14px;
}
@media (min-width: 400px) {
  :root {
    --fluid-body-xs: calc(
					14px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  :root {
    --fluid-body-xs: 16px;
  }
}

h1,
.fluid-h1 {
  font-size: var(--fluid-h1);
}

h1.global-cs {
  font-size: var(--glob-h1);
}

h2,
.fluid-h2 {
  font-size: var(--fluid-h2);
}

h2.global-cs {
  font-size: var(--glob-h2);
  color: #5a5a5a;
  font-weight: 500;
  line-height: normal;
}

h3,
.fluid-h3 {
  font-size: var(--fluid-h3);
}

h3.global-cs {
  font-size: var(--glob-h3);
}

h4,
.fluid-h4 {
  font-size: var(--fluid-h4);
}

h4.global-cs {
  font-size: var(--glob-h4);
  line-height: 24px;
  color: #5a5a5a;
  font-weight: 700;
}

h5,
.fluid-h5 {
  font-size: var(--fluid-h5);
}

h6,
.fluid-h6 {
  font-size: var(--fluid-h6);
}

html,
.fluid-body {
  font-size: var(--fluid-body);
}

.fluid-body-sm {
  font-size: var(--fluid-body-sm);
}

/**
 * Supers!
 * An example of a separate functionality module
 * To note this module integrates with the theme settings
 * This should be a normal aspect of modules for easy integration with existing projects
 **/
/**
 * Generic footer layout engine 📏
 **/
.s-footer {
  display: flex;
  flex-flow: column nowrap;
  background-color: #5a5a5a;
}
.s-footer .s-footer-inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem 1rem 1rem;
}
.s-footer .s-footer-grid {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  width: 230px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .s-footer .s-footer-grid {
    width: 100%;
    justify-content: space-between;
  }
}
.s-footer .s-footer-cell {
  padding: 0.6rem 0.6rem;
  display: block;
  max-width: 100%;
  width: 230px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-cell {
    width: auto;
  }
}
.s-footer .s-footer-logo-container .s-footer-logo {
  width: 100%;
  max-width: 100%;
  max-height: 60px;
  height: 50px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-logo-container {
    width: auto;
  }
}
.s-footer .s-footer-menu {
  width: 230px;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu {
    width: auto;
  }
}
.s-footer .s-footer-menu ul {
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  width: 100%;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu ul {
    margin-left: -1rem;
  }
}
.s-footer .s-footer-menu ul li {
  width: auto;
  font-weight: bold;
  text-align: center;
}
.s-footer .s-footer-menu ul li a {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
@media (min-width: 768px) {
  .s-footer .s-footer-menu ul {
    flex-flow: row nowrap;
  }
}
.s-footer .s-footer-copyright {
  text-align: center;
}

.s-footer-copyright-row {
  background-color: #ebebeb;
  color: white;
}
.s-footer-copyright-row a {
  color: white;
  transition: all 0.3s ease;
}
.s-footer-copyright-row a:hover, .s-footer-copyright-row a:active, .s-footer-copyright-row a:focus {
  color: #ebebeb;
}
.s-footer-copyright-row i {
  padding: 0.3rem;
}
.s-footer-copyright-row .inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 20px 1rem 20px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 480px) {
  .s-footer-copyright-row .inner-wrap {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
.s-footer-copyright-row .right-cell {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .s-footer-copyright-row .right-cell {
    flex-flow: row nowrap;
  }
}
.s-footer-copyright-row .right-cell .foundation-link {
  display: flex;
  margin: 1rem;
  padding-top: 1rem;
  margin-top: 0;
  text-decoration: underline;
  font-weight: 500;
}
@media (min-width: 480px) {
  .s-footer-copyright-row .right-cell .foundation-link {
    padding-top: unset;
    margin: unset;
    margin-right: 1rem;
  }
}
.s-footer-copyright-row .right-cell .footer-socials {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
@media (min-width: 768px) {
  .s-footer-copyright-row .right-cell .footer-socials {
    margin-top: 0;
  }
}

.s-footer-menu a {
  color: #ebebeb;
  font-weight: 500;
}
.s-footer-menu a:hover, .s-footer-menu a:active, .s-footer-menu a:focus {
  filter: brightness(110%);
}
.s-footer-menu .is-active a {
  background: transparent;
  font-weight: 700;
  color: #dbc992;
}

@media (max-width: 1024px) {
  .s-footer-grid {
    justify-content: center;
  }
  .s-footer-grid .menu {
    justify-content: center;
  }
}
.s-footer-grid .menu-item {
  flex-shrink: 0;
}

/**
 * Super section :D
 * A section that contains content
 * The section/inner-wrap is set to flex column for easy placement of children elements 🙌
 **/
.s-section {
  display: flex;
  flex-flow: column nowrap;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 100%;
}
.s-inner-wrap {
  display: flex;
  flex-flow: column nowrap;
  padding: 75px 20px;
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-self: stretch;
  align-self: stretch;
  overflow: hidden;
  z-index: 1;
  flex-grow: 1;
}
.s-center > .super-inner-wrap,
.s-center .s-inner-wrap {
  align-items: center;
  justify-content: center;
}
.s-flex-end > .super-inner-wrap,
.s-flex-end .s-inner-wrap {
  justify-content: flex-end;
}
.s-constrain .super-inner-wrap,
.s-constrain .s-inner-wrap {
  width: 1200px;
}

.featured-hero {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.featured-hero h1 {
  color: #ffffff;
  max-width: 905px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 0.5rem;
  line-height: 1.2;
}

/**
 * Buttons
 **/
.button.donate {
  background: #2A3352;
  color: #ffffff;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 161px;
  position: relative;
  height: 38px;
  border: none;
  white-space: nowrap;
  font-family: montserrat, sans-serif !important;
  border-radius: 5px 5px 25px 5px;
}
.button.donate span {
  padding-top: 3px;
}
.button.donate svg {
  margin-right: 0.5rem;
  height: 18.21px;
  overflow: visible;
}
.button.donate svg path {
  transition: all 0.3s ease-in-out;
}
.button.donate:hover {
  color: #ffffff;
}
.button.donate:hover svg path {
  fill: #ffffff;
}
.button.donate:hover svg path.heart {
  transform: translateY(-3px);
}

.button {
  background: transparent;
  border: 3px solid #a99969;
  color: #191919;
  letter-spacing: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: fit-content;
  font-family: acumin-pro !important;
  transition: all 0.3s ease-in-out;
}
.button:hover {
  color: #191919;
}
.button .wp-block-button__link {
  background: none;
  border: none;
  padding: 0.25rem;
}
.button .wp-block-button__link:after, .button .wp-block-button__link:hover, .button .wp-block-button__link:hover:after {
  background: none;
  border: none;
  border-bottom: none;
  padding: 0.25rem;
}

.link,
.wp-block-button__link {
  color: #5a5a5a !important;
  border-bottom: 2px solid #a99969;
  border-radius: 0;
  position: relative;
  padding-right: 1rem;
  font-weight: 700;
  padding-left: 0;
  font-family: acumin-pro, -apple-system, "Ubuntu", sans-serif;
  padding-bottom: 0.25rem;
  background: none;
  padding-top: 0;
}
.link:after,
.wp-block-button__link:after {
  content: " ";
  background: url(../images/right-chevron.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 13px;
}
.link:hover,
.wp-block-button__link:hover {
  color: #5a5a5a;
  padding-right: 1.25rem;
  border-bottom: 2px solid #dbc992;
}
.link:hover:after,
.wp-block-button__link:hover:after {
  background: url(../images/right-chevron-light.svg);
}

.gold-btn-ctn {
  display: flex;
  align-items: center;
  gap: 5px;
}
.gold-btn-ctn span {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  background-color: #dbc992;
  padding: 10px 16px 8px;
  border-radius: 40px;
  display: inline-block;
  transition: all 0.3s ease;
}
.gold-btn-ctn:hover span {
  background-color: #2A3352;
  color: #ffffff;
}
.gold-btn-ctn:hover svg {
  transition: all 0.3s ease;
}
.gold-btn-ctn:hover svg circle {
  fill: #2A3352;
}
.gold-btn-ctn:hover svg path {
  fill: #ffffff;
}

.btn-ps-wavr {
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px 5px 25px 5px;
  border: 2px solid #5a5a5a;
  color: #5a5a5a;
  min-width: 160px;
  width: 100%;
  display: inline-flex;
  max-width: fit-content;
  padding: 13px 10px 10px 16px;
  transition: all 0.3s ease;
  align-items: center;
}
.btn-ps-wavr:hover {
  background-color: #5a5a5a;
  color: #ffffff;
}

/**
 * Link styling
 * Links can get pretty fancy so the setting partials do not get involved
 **/
a {
  color: #a99969;
  transition: all 0.3s ease;
}
a:hover, a:focus {
  color: #948455;
}
a:active {
  color: #746742;
}

#topbar {
  background: #dbc992;
}
@media (max-width: 767.98px) {
  #topbar {
    min-height: 68px;
  }
}
#topbar .container {
  max-width: 1180px;
  width: 100%;
  padding-left: 60px;
  margin: 0 auto;
}
@media (max-width: 575px) {
  #topbar .container {
    padding-right: 10px;
    padding-left: 24px;
  }
}
#topbar .container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#topbar .container .row .col-left-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
#topbar .container .row .col-left-wrapper #logo img {
  max-width: 150px;
  width: 100%;
  margin-bottom: -70px;
}
@media (max-width: 575px) {
  #topbar .container .row .col-left-wrapper #logo img {
    max-width: 290px;
    margin-bottom: -170px;
  }
}
#topbar .container .row .col-left-wrapper .mega-close {
  left: inherit !important;
  right: 15px !important;
  top: 10px !important;
}
#topbar .container .row .col-left-wrapper .mega-close::before {
  font-size: 0 !important;
  background-image: url("../images/close-icon-pop.svg");
  background-repeat: no-repeat;
  background-position: right;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap .mega-menu-toggle .mega-toggle-block .mega-toggle-animated-inner {
  top: 22px;
  background-color: #5a5a5a !important;
  height: 2px;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap .mega-menu-toggle .mega-toggle-block .mega-toggle-animated-inner::after {
  height: 2px;
  background-color: #5a5a5a !important;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap .mega-menu-toggle .mega-toggle-block .mega-toggle-animated-inner::before {
  height: 2px;
  background-color: #5a5a5a !important;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu {
  width: 100%;
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  column-gap: 25px;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu #searchButton {
  background-color: #A9976880;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 164px;
  justify-content: space-between;
  padding: 3px 9px;
  padding-left: 16px;
  min-height: 36px;
  max-width: 190px;
  margin-left: 40px;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu #searchButton span {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .button.donate {
  width: 100%;
  max-width: 190px;
  margin-left: 40px;
  height: 44px;
  padding-top: 11px;
  margin-top: -20px;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  padding: 0;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a {
    font-size: 16px;
    letter-spacing: 0.8px;
    padding-left: 50px;
    padding-right: 50px;
  }
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a .mega-indicator {
    float: inherit !important;
  }
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a .mega-indicator::after {
    transition: none !important;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a:hover {
  color: #2A3352;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a:hover .mega-indicator::after {
  transform: rotate(180deg) !important;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a:hover .mega-indicator::after {
    transform: none !important;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a .mega-indicator::after {
  top: -1px;
  transition: all 0.2s ease;
  left: -2px;
}
@media (max-width: 1199px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item a {
    font-size: 12px;
    height: inherit;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item .mega-sub-menu:nth-child(2) {
  position: fixed !important;
  max-width: 922px !important;
  width: calc(100% - 25px);
  left: 50% !important;
  margin: 0 auto;
  transform: translateX(-50%) !important;
  top: 64px !important;
  background-color: #ffffff !important;
  z-index: -1;
  border-bottom-right-radius: 30px;
  border: 2px solid #a99969;
  border-top: none;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item .mega-sub-menu:nth-child(2) {
    position: relative !important;
    max-width: 100% !important;
    width: 100% !important;
    left: 0 !important;
    top: inherit !important;
    transform: none !important;
    margin-top: 20px;
    border-top: 2px solid #a99969;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .mega-menu-item .mega-sub-menu .mega-menu-row > .mega-sub-menu {
  display: flex;
  align-items: center;
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .home-logo {
  display: none;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .home-logo {
    display: block;
    padding-left: 30px;
    padding-right: 40px;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu #searchButton {
  display: none;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu #searchButton {
    display: flex;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .button.donate {
  display: none;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .button.donate {
    display: block;
  }
}
#topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu .home-logo img {
  max-width: 120px;
}
@media (max-width: 1199px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu {
    column-gap: 8px;
  }
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu {
    background-image: linear-gradient(#dbc992d9, #dbc992d9), url("../images/pop-head-bg.png");
    flex-direction: column;
    margin-left: 0;
    padding: 30px 0 30px;
    row-gap: 29px;
  }
  #topbar .container .row .col-left-wrapper .mega-menu-wrap #mega-menu-header-menu li.mega-toggle-on .mega-menu-link .mega-indicator::after {
    content: "" !important;
    background-color: #5a5a5a !important;
    width: 14px !important;
    height: 2px !important;
    transition: none !important;
    top: 5px;
  }
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-left-wrapper .mega-menu-wrap {
    display: block;
  }
}
#topbar .container .row .col-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 991.98px) {
  #topbar .container .row .col-right {
    display: none;
  }
}
#topbar .container .row .col-right .right {
  display: flex;
  flex-direction: row;
  width: max-content;
}
#topbar .container .row .col-right .right #searchButton {
  background-color: #A9976880;
  height: 100%;
  flex-direction: row;
  align-items: center;
  min-width: 164px;
  width: 100%;
  justify-content: space-between;
  padding: 3px 9px;
  padding-left: 16px;
  display: flex;
}
#topbar .container .row .col-right .right #searchButton span {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
@media (max-width: 1199px) {
  #topbar .container .row .col-right .right #searchButton {
    min-width: 125px;
  }
}
#topbar .container .row .col-right .right .button.donate {
  border-radius: 5px 5px 25px 5px;
  min-height: 36px;
  font-family: montserrat, sans-serif !important;
  min-width: 139px;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: normal;
  justify-content: start;
  padding-left: 18px;
  margin: 0 5px 0 8px;
}
#topbar .container .row .col-right .right .button.donate span {
  padding-top: 3px;
  letter-spacing: 0 !important;
}
#topbar .container .row .col-right .right .social {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
@media (max-width: 1199px) {
  #topbar .container {
    padding-right: 35px;
    padding-left: 35px;
  }
}
#topbar:hover {
  position: sticky;
  top: 0;
}

@media (max-width: 991.98px) {
  .mega-menu-item .mega-menu-row .mega-menu-columns-4-of-12 {
    display: none !important;
  }
}
.mega-menu-item .mega-menu-row .mega-menu-columns-4-of-12 .mega-menu-item {
  position: relative;
}
.mega-menu-item .mega-menu-row .mega-menu-columns-4-of-12 .mega-menu-item h4 {
  position: absolute;
  bottom: -11px;
  left: 10px;
  color: #ffffff !important;
  font-family: montserrat, sans-serif !important;
  font-size: 45px !important;
  font-weight: 700 !important;
  line-height: 45px;
  text-transform: uppercase !important;
  padding: 0 !important;
}
.mega-menu-item .mega-menu-row .mega-menu-columns-4-of-12 .mega-menu-item img {
  width: 100%;
  border-top-right-radius: 80px;
  min-height: 320px;
  object-fit: cover;
}
.mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 {
  padding: 22px 20px 20px 44px !important;
}
@media (max-width: 991.98px) {
  .mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 {
    padding: 22px 30px 20px 30px !important;
  }
}
.mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
  justify-content: space-between;
  row-gap: 12px;
}
@media (max-width: 991.98px) {
  .mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul {
    flex-direction: column;
  }
}
.mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul li {
  width: 48.7%;
}
@media (max-width: 991.98px) {
  .mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul li {
    min-width: fit-content;
  }
}
.mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul li a {
  display: inline-block;
  width: 100%;
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-bottom: 1px solid #5a5a5a;
  padding-bottom: 7px !important;
  text-transform: capitalize !important;
  height: 100%;
}
@media (max-width: 991.98px) {
  .mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu ul li a {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.mega-menu-item .mega-menu-row .mega-menu-columns-8-of-12 .mega-sub-menu .widget_text h4.mega-block-title {
  color: #5a5a5a !important;
  font-family: montserrat, sans-serif !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: 0.7px !important;
  text-transform: uppercase !important;
  padding: 0 !important;
  margin-bottom: 13px !important;
}

.mega-visit-cs .mega-menu-columns-8-of-12 .mega-sub-menu li:first-child ul {
  justify-content: start !important;
  gap: 12px !important;
}
.mega-visit-cs .mega-menu-columns-8-of-12 .mega-sub-menu li:first-child ul li {
  width: auto !important;
}
@media (max-width: 991.98px) {
  .mega-visit-cs .mega-menu-columns-8-of-12 .mega-sub-menu li:first-child ul li {
    width: 48% !important;
  }
}
.mega-visit-cs .mega-menu-columns-8-of-12 .mega-sub-menu li:first-child ul li a {
  font-weight: 700 !important;
}
.mega-visit-cs .mega-menu-columns-8-of-12 .mega-sub-menu .widget_text h4.mega-block-title {
  margin-top: 30px !important;
}

.header-popup {
  display: none;
}

.header-popup.active {
  display: block;
}

header#topbar {
  width: 100%;
  background: #dbc992;
  position: relative;
  z-index: 10;
}
header#topbar .inner {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 53px;
  position: relative;
}
@media (min-width: 768px) {
  header#topbar .inner {
    padding: 0.5rem 0;
  }
}
header#topbar .inner #logo {
  width: 170px;
  display: block;
  position: absolute;
  transform: translateX(-50%);
  left: 10%;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
header#topbar .inner #logo a {
  position: absolute;
  top: 0.5rem;
}
header#topbar .inner .left {
  padding: 0 1rem;
}
header#topbar .inner .left a.social {
  margin-right: 0.5rem;
  display: none;
}
@media (min-width: 768px) {
  header#topbar .inner .left a.social {
    display: inline;
  }
}
header#topbar .inner .left a.social svg path {
  fill: #5a5a5a;
}
header#topbar .inner .left a#searchButton {
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: #5a5a5a;
  display: inline-block;
  margin-left: 1rem;
}
@media (min-width: 768px) {
  header#topbar .inner .left a#searchButton {
    border-bottom: 2px solid #a99969;
  }
}
header#topbar .inner .left a#searchButton span {
  margin-right: 0.5rem;
  margin-left: 0.2rem;
  color: #191919;
  letter-spacing: 1px;
  display: none;
}
@media (min-width: 768px) {
  header#topbar .inner .left a#searchButton span {
    display: inline;
  }
}
header#topbar .inner .left a#searchButton svg {
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
}
header#topbar .inner .right {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  min-width: auto;
  justify-content: flex-end;
}
@media (min-width: 768px) {
  header#topbar .inner .right {
    min-width: 287px;
  }
}
header#topbar .inner .right .button {
  display: none;
}
@media (min-width: 768px) {
  header#topbar .inner .right .button {
    justify-content: space-between;
    display: flex;
  }
}

#toggleMenu,
#closeMenu {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 2rem;
  width: 60px;
  margin-right: 5px;
}
#toggleMenu span,
#closeMenu span {
  color: #191919 !important;
}
#toggleMenu svg,
#closeMenu svg {
  height: 26px;
}

#toggleMenu svg#mobileHamburger {
  display: block;
}
@media (min-width: 768px) {
  #toggleMenu svg#mobileHamburger {
    display: none;
  }
}
#toggleMenu svg#hamburger {
  display: none;
}
@media (min-width: 768px) {
  #toggleMenu svg#hamburger {
    display: block;
  }
}

#closeMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75pxrem;
  display: none;
  padding-top: 1rem;
}
@media (min-width: 768px) {
  #closeMenu {
    padding-top: 0;
  }
}

#mainMenu {
  display: none;
  background: #dbc992;
  padding-top: 9rem;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  right: -800px;
  transition: right 0.3s ease-in-out;
  opacity: 1;
  max-width: 100%;
  overflow: hidden;
}
@media (min-width: 768px) {
  #mainMenu {
    transition: top 0.3s ease-in-out;
    top: -800px;
    right: 0;
    display: flex;
  }
}
#mainMenu .inner {
  max-width: 1050px;
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#mainMenu .inner nav {
  width: 100%;
}
#mainMenu .inner ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 100%;
}
#mainMenu .inner ul li {
  margin-bottom: 1rem;
}
#mainMenu .inner ul li .toggleMenuItem {
  display: none;
  background: none;
  border: none;
}
#mainMenu .inner ul li a {
  text-transform: uppercase;
  color: #191919;
  font-weight: 500;
  font-size: 1.25rem;
  transition: all 0.3s ease-in-out;
}
#mainMenu .inner ul li a:hover {
  color: #333333;
}
#mainMenu .inner ul ul {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 1rem;
}
#mainMenu .inner ul ul li a {
  font-weight: 500;
  text-transform: none;
  color: #5a5a5a;
  font-size: 1rem;
}
#mainMenu .inner ul ul li a:hover {
  color: #747474;
}

#mobileDonor {
  display: none;
  width: 100%;
  max-width: 300px;
  margin: 0 auto 1rem;
  font-size: 1.2rem;
  height: auto;
  padding: 0.75rem 1.5rem;
}

body.active #toggleMenu {
  display: none;
}
body.active #closeMenu {
  display: flex;
}
body.active #mainMenu {
  display: flex;
  opacity: 1;
  top: 0;
  right: 0;
  position: absolute;
}
@media (min-width: 768px) {
  body.active #mainMenu {
    top: 53px;
    right: 0;
  }
}
@media (max-width: 767px) {
  body.active header#topbar .inner #logo {
    display: none;
  }
  body.active #mobileDonor {
    margin-top: 4.5rem;
    display: flex;
  }
}
body.active header#topbar .inner .left a#searchButton span {
  display: inline;
}

@media (max-width: 767px) {
  #mainMenu {
    display: flex;
    padding-top: 4rem;
    z-index: 5;
    position: fixed;
  }
  #mainMenu .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  #mainMenu .inner ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  #mainMenu .inner ul li {
    margin-bottom: 1.5rem;
    width: 100%;
    border-bottom: 1px solid #a99969;
    padding-left: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    font-weight: 500;
    position: relative;
  }
  #mainMenu .inner ul li:last-child {
    border: none;
  }
  #mainMenu .inner ul li .toggleMenuItem {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: none;
    border: none;
    position: absolute;
    right: 2rem;
    cursor: pointer;
    padding: 1rem;
    top: -1rem;
    z-index: 5;
  }
  #mainMenu .inner ul li .toggleMenuItem:after {
    content: "";
    display: block;
    background: url(../images/plus.svg);
    height: 19px;
    width: 21px;
    cursor: pointer;
  }
  #mainMenu .inner ul li .toggleMenuItem.active:after {
    background: url(../images/minus.svg) no-repeat center center;
  }
  #mainMenu .inner ul ul {
    display: none;
    flex-direction: column;
    padding-left: 0;
  }
  #mainMenu .inner ul ul li {
    border: none;
    padding: 0;
  }
  #mainMenu .inner ul li.active ul {
    display: flex;
    flex-direction: column;
  }
  #mainMenu .inner ul li.active ul li {
    width: 100%;
  }
}
footer#footer {
  background: #dbc992;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;
}
@media (max-width: 767.98px) {
  footer#footer {
    padding: 16px 24px 32px;
  }
}
footer#footer .inner {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  footer#footer .inner {
    flex-wrap: nowrap;
  }
}
footer#footer .inner .contact-left .button.donate {
  display: none;
}
@media (max-width: 767.98px) {
  footer#footer .inner .contact-left .button.donate {
    display: flex;
    align-items: center;
    margin: 23px 0 36px;
  }
}
footer#footer .inner .contact-left p.phone {
  display: none;
  text-transform: uppercase;
  color: #5a5a5a;
  margin-bottom: 9px;
  margin-top: 0;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  width: 100%;
}
footer#footer .inner .contact-left p.phone a {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.2s ease;
}
@media (max-width: 767.98px) {
  footer#footer .inner .contact-left p.phone {
    display: block;
    padding-left: 12px;
  }
}
footer#footer .inner .contact-left,
footer#footer .inner .contact-right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  order: 1;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
footer#footer .inner .contact-right {
  justify-content: flex-start;
  align-items: start;
  font-size: 18px;
  padding-top: 16px;
}
footer#footer .inner .contact-right .socials {
  display: none;
}
@media (max-width: 767.98px) {
  footer#footer .inner .contact-right .socials {
    display: flex;
  }
}
footer#footer .inner .contact-right .phone {
  text-transform: uppercase;
  color: #5a5a5a;
  margin-bottom: 16px;
  margin-top: 0;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}
footer#footer .inner .contact-right .phone a {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  transition: all 0.2s ease;
}
footer#footer .inner .contact-right .phone a:hover {
  color: #2A3352;
}
footer#footer .inner .contact-right address {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}
@media (max-width: 767.98px) {
  footer#footer .inner .contact-right address {
    display: none;
  }
}
footer#footer .inner .contact-right a.button {
  min-width: 155px;
}
footer#footer .inner #footerLogo {
  width: 100%;
  max-width: 134px;
}
@media (max-width: 767px) {
  footer#footer .inner .contact-left {
    margin-bottom: 0;
  }
  footer#footer .inner .contact-right {
    width: 50%;
    order: 3;
    display: none;
  }
}
footer#footer .inner .socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 17px 0 0;
}
@media (max-width: 767.98px) {
  footer#footer .inner .socials {
    width: 100%;
    margin: 0;
    justify-content: start;
    padding-left: 12px;
  }
}
footer#footer .inner .socials a.social {
  margin-right: 14px;
  height: 22px;
}
footer#footer .inner .socials a.social:last-child {
  margin-right: 0;
}
footer#footer .inner .socials a.social svg path {
  fill: #5a5a5a;
}
footer#footer .email {
  width: 100%;
  margin-top: 2rem;
  order: 3;
}
@media (max-width: 767.98px) {
  footer#footer .email {
    margin-top: 10px;
    padding: 0 12px;
  }
}
@media (min-width: 768px) {
  footer#footer .email {
    margin-top: 0;
    width: auto;
    order: 2;
    padding-top: 16px;
  }
}
footer#footer .email h4 {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  margin: 0;
}
footer#footer .email p {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  margin: 4px 0 10px;
}
footer#footer .email .button.donate {
  min-height: 44px;
  max-width: 166px;
  padding-left: 20px;
  justify-content: start;
  padding-top: 12px;
  margin-bottom: 28px;
}
@media (max-width: 767.98px) {
  footer#footer .email .button.donate {
    display: none;
  }
}
footer#footer .menu {
  order: 1;
  margin: 0;
  margin-right: 45px;
  padding-top: 16px;
}
footer#footer .menu address {
  display: none;
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 9px;
}
@media (max-width: 767.98px) {
  footer#footer .menu address {
    display: block;
  }
}
@media (max-width: 767px) {
  footer#footer .menu {
    margin-top: 0;
    order: 2;
    width: 58%;
    justify-content: center;
    padding-left: 2rem;
    margin-right: 0;
  }
  footer#footer .menu nav {
    padding-left: 0;
    margin-bottom: 60px;
  }
  footer#footer .menu nav ul {
    width: 100%;
  }
}
footer#footer .menu li {
  margin-bottom: 18px;
  font-size: 14px;
}
footer#footer .menu li a {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  transition: all 0.2s ease;
}
footer#footer .menu li a:hover {
  color: #2A3352;
}
footer#footer .menu ul {
  padding: 0 !important;
}

#footer #mc_embed_signup #mc_embed_signup_scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767.98px) {
  #footer #mc_embed_signup #mc_embed_signup_scroll {
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  #footer #mc_embed_signup .mc-field-group {
    width: 100%;
  }
}
#footer #mc_embed_signup #mce-EMAIL {
  outline: none;
  padding: 0.5rem 0.5rem;
  width: 100%;
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  border: none;
  background: rgba(255, 255, 255, 0.5);
  min-height: 36px;
  text-align: center;
  max-width: 154px;
}
@media (max-width: 767.98px) {
  #footer #mc_embed_signup #mce-EMAIL {
    max-width: 100%;
    text-align: start;
    padding-left: 20px;
    margin: 0;
  }
}
@media (max-width: 767.98px) {
  #footer #mc_embed_signup .optionalParent {
    width: 100%;
  }
}
#footer #mc_embed_signup input#mc-embedded-subscribe {
  cursor: pointer;
  text-transform: capitalize;
  color: #5a5a5a;
  text-align: center;
  font-family: montserrat, sans-serif !important;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px 5px 20px 5px;
  border: 2px solid #5a5a5a;
  padding: 11px 19px 9px;
  margin-left: 6px;
  transition: all 0.3s ease;
}
@media (max-width: 767.98px) {
  #footer #mc_embed_signup input#mc-embedded-subscribe {
    max-width: 100%;
    width: 100%;
    margin: 3px 0 0 !important;
    min-height: 40px;
  }
}
#footer #mc_embed_signup input#mc-embedded-subscribe:hover {
  background-color: #5a5a5a;
  color: #ffffff;
}

#footerBottom {
  background: #A99768;
  width: 100%;
}
#footerBottom .inner {
  max-width: 610px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: start;
  text-align: center;
  flex-direction: column;
}
@media (max-width: 767.98px) {
  #footerBottom .inner {
    padding: 22px 0;
  }
}
@media (min-width: 768px) {
  #footerBottom .inner {
    flex-direction: row;
  }
}
#footerBottom .inner p {
  color: #ffffff;
  font-family: montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
#footerBottom .inner p.terms {
  margin-left: 1rem;
}
@media (max-width: 767px) {
  #footerBottom .inner p.terms span {
    display: none;
  }
}
#footerBottom .inner p a {
  text-decoration: underline;
  color: #ffffff;
}
#footerBottom .inner p a:hover {
  color: #2A3352;
}

.c-grid {
  --grid-col-sm: 1fr;
  --grid-col-md: 1fr;
  --grid-col-lg: 2fr;
  --grid-gap: 10px;
  display: grid;
  grid-template-columns: var(--grid-col-sm);
  grid-gap: var(--grid-gap);
}
@media (min-width: 768px) {
  .c-grid {
    grid-template-columns: var(--grid-col-md);
  }
}
@media (min-width: 1024px) {
  .c-grid {
    grid-template-columns: var(--grid-col-lg);
  }
}
.c-grid .cell {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
}

.f-grid {
  display: flex;
  flex-flow: row wrap;
  --cell-size-sm: 100%;
  --cell-size-md: 50%;
  --cell-size-lg: 33.33%;
  --grid-gap: 30px;
  margin: calc(var(--grid-gap) * 0.5 * -1);
}
.f-grid .cell {
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  flex-basis: calc(var(--cell-size-sm) - var(--grid-gap));
  margin: calc(var(--grid-gap) * 0.5);
}
@media (min-width: 768px) {
  .f-grid .cell {
    flex-basis: calc(var(--cell-size-md) - var(--grid-gap));
  }
}
@media (min-width: 1024px) {
  .f-grid .cell {
    flex-basis: calc(var(--cell-size-lg) - var(--grid-gap));
  }
}

section,
#homeEvents h2,
footer#footer,
#footerBottom,
#visitPlan,
#impactTimeline,
.featured-hero h1,
.page .entry-content,
.error404 .entry-content,
#searchResults {
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 500px) {
  section,
#homeEvents h2,
footer#footer,
#footerBottom,
#visitPlan,
#impactTimeline,
.featured-hero h1,
.page .entry-content,
.error404 .entry-content,
#searchResults {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 640px) {
  section,
#homeEvents h2,
footer#footer,
#footerBottom,
#visitPlan,
#impactTimeline,
.featured-hero h1,
.page .entry-content,
.error404 .entry-content,
#searchResults {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (min-width: 1024px) {
  section,
#homeEvents h2,
footer#footer,
#footerBottom,
#visitPlan,
#impactTimeline,
.featured-hero h1,
.page .entry-content,
.error404 .entry-content,
#searchResults {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide {
  width: 240px;
}

.event-card h3 {
  color: #707070;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 0;
}
.event-card h3 {
  font-size: 16px;
}
@media (min-width: 400px) {
  .event-card h3 {
    font-size: calc(
					16px + 2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  .event-card h3 {
    font-size: 18px;
  }
}
.event-card .card-date {
  color: #a99969;
  font-weight: bold;
}
.event-card .image {
  position: relative;
}
.event-card .image img {
  object-fit: cover;
  object-position: top center;
  height: 195px;
  width: 100%;
}
@media (min-width: 768px) {
  .event-card .image img {
    height: 237px;
  }
}
@media (min-width: 1024px) {
  .event-card .image img {
    height: 279px;
  }
}
.event-card .image span {
  transition: all 0.3s ease-in-out;
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 1rem;
  color: #ffffff;
  font-weight: 700;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 60%);
  width: 100%;
  left: 0;
  padding-bottom: 1rem;
  height: 125px;
}
.event-card .image span span {
  border-bottom: 2px solid #a99969;
  padding-left: 0;
  padding-right: 1.25rem;
  padding-bottom: 0.25rem;
  position: relative;
  top: 75px;
  left: 1rem;
  background: none;
  opacity: 1;
}
.event-card .image span span:after {
  content: " ";
  background: url(../images/right-chevron.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 13px;
}
.event-card .image:hover span {
  opacity: 1;
}
@media (hover: none) {
  .event-card .image:hover span {
    opacity: 0;
  }
}

.event-category-card {
  width: calc(50% - 0.27rem);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
}
.event-category-card:nth-child(2n) {
  margin-right: 0;
}
@media (min-width: 1024px) {
  .event-category-card {
    width: calc(33% - 1rem);
  }
  .event-category-card:nth-child(2n) {
    margin-right: 0.5rem;
  }
  .event-category-card:nth-child(3n) {
    margin-right: 0;
  }
}
.event-category-card .image {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 133px;
}
@media (min-width: 1024px) {
  .event-category-card .image {
    height: 264px;
  }
}
.event-category-card .image h3 {
  color: #ffffff;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  line-height: 1;
  font-family: montserrat, sans-serif;
}
.event-category-card .image h3 {
  font-size: 21px;
}
@media (min-width: 400px) {
  .event-category-card .image h3 {
    font-size: calc(
					21px + 11 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  .event-category-card .image h3 {
    font-size: 32px;
  }
}
.event-category-card .image .content {
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  justify-content: flex-end;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, transparent 50%);
}
.event-category-card .image span {
  position: absolute;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  color: #ffffff;
  border-bottom: 2px solid #a99969;
  padding-bottom: 0.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.event-category-card .image:hover h3 {
  padding-bottom: 2rem;
  font-family: montserrat;
}
.event-category-card .image:hover span {
  opacity: 1;
}
@media (hover: none) {
  .event-category-card .image:hover h3 {
    padding-bottom: 0;
  }
  .event-category-card .image:hover span {
    opacity: 0;
  }
}

.tribe-events-calendar-list__event-datetime .tribe-event-date-start,
.single-tribe_events .tribe-blocks-editor .tribe-events-schedule .tribe-events-schedule__datetime,
.tribe-blocks-editor .tribe-related-event-info .timezone,
.tribe-blocks-editor .tribe-related-event-info .tribe-event-date-start,
.tribe-blocks-editor .tribe-related-event-info .tribe-event-time {
  color: #a99969;
  font-weight: bold;
}

.tribe-events-calendar-list__event-title,
.single-tribe_events .tribe-events-single-event-title,
.tribe-block__related-events__title,
.single-tribe_events ul.tribe-related-events .tribe-related-events-title {
  text-transform: uppercase;
}

.tribe-events .tribe-events-calendar-list__event-title-link,
.single-tribe_events .tribe-events-single-event-title,
.tribe-block__related-events__title,
.single-tribe_events ul.tribe-related-events .tribe-related-events-title {
  color: #707070 !important;
}

.tribe-events-header__breadcrumbs {
  display: none;
}

.tribe-events .tribe-events-l-container {
  padding-top: 2rem !important;
}

#tribe-events-view-selector-content .tribe-events-c-view-selector__list {
  min-width: 110px;
}

.tribe-events-sub-nav li a {
  background: none;
  color: #a99969 !important;
  transition: all 0.3s ease;
}
.tribe-events-sub-nav li a:hover, .tribe-events-sub-nav li a:focus {
  color: #948455 !important;
}
.tribe-events-sub-nav li a:active {
  color: #746742 !important;
}

.events-wrapper button.tribe-events-calendar-month__day-cell.tribe-events-calendar-month__day-cell--mobile.tribe-events-calendar-month__day-cell--selected {
  background-color: #969696 !important;
}
.events-wrapper .tribe-events .tribe-events-calendar-month__mobile-events-icon--event {
  background-color: #a99969 !important;
}
.events-wrapper span.tribe-events-calendar-day__event-datetime-featured-text.tribe-common-a11y-visual-hide {
  color: #a99969 !important;
}
.events-wrapper .tribe-events .tribe-events-calendar-day__event--featured::after {
  background-color: #dbc992 !important;
}
.events-wrapper .tribe-events-calendar-list__event-datetime-featured-text.tribe-common-a11y-visual-hide {
  color: #a99969 !important;
}
.events-wrapper .tribe-events-calendar-list__event-date-tag-datetime::after {
  background-color: #dbc992 !important;
}
.events-wrapper .tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button {
  color: #5a5a5a;
  border: 1px solid #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 13px;
}
@media (max-width: 575px) {
  .events-wrapper .tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button {
    border: 2px solid #5a5a5a;
    border-radius: 5px 5px 25px 5px;
    width: fit-content;
  }
}
.events-wrapper .tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button:hover {
  background-color: #5a5a5a !important;
}
.events-wrapper .tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button:hover svg {
  color: #ffffff !important;
}
.events-wrapper .tribe-common-c-btn-border.tribe-events-c-subscribe-dropdown__button svg {
  color: #5a5a5a !important;
}
.events-wrapper .tribe-events .tribe-events-calendar-month__calendar-event--featured:before {
  background-color: #5a5a5a !important;
}
.events-wrapper .tribe-common-h8 {
  color: #5a5a5a !important;
}
.events-wrapper .tribe-events-calendar-month__multiday-event-bar {
  background-color: #dbc992 !important;
}
.events-wrapper .tribe-events-calendar-month__multiday-event-bar-inner {
  background-color: transparent !important;
}
.events-wrapper .tribe-common .tribe-common-c-svgicon {
  color: #a99969 !important;
}
.events-wrapper input#tribe-events-events-bar-keyword {
  outline: none;
  font-family: montserrat, sans-serif;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 16px;
}
.events-wrapper .tribe-common-c-btn.tribe-events-c-search__button {
  color: #bababa !important;
  font-family: montserrat, sans-serif !important;
  font-size: 12.044px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
  background-color: transparent;
  border: 2px solid #bababa !important;
  border-radius: 12px !important;
  padding: 8px 15px !important;
}
.events-wrapper .tribe-common-c-btn.tribe-events-c-search__button:hover {
  background-color: #bababa !important;
  color: #ffffff !important;
}
.events-wrapper span.tribe-events-c-view-selector__list-item-text {
  color: #bababa !important;
  font-family: montserrat, sans-serif !important;
  font-size: 12.044px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.events-wrapper .tribe-events-c-view-selector__list-item-link::after {
  background-color: #bababa !important;
}
.events-wrapper .tribe-events-calendar-month__calendar-event-featured-image-wrapper {
  display: none;
}

#searchOverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(51, 74, 255, 0.9);
  display: none;
  top: 0;
  left: 0;
  z-index: 100;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#searchOverlay .search-inner {
  max-width: 1050px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
#searchOverlay #closeSearch {
  position: absolute;
  top: 4rem;
  right: 2rem;
  background: none;
  border: none;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
#searchOverlay #closeSearch svg {
  margin-right: 0.5rem;
}
#searchOverlay #closeSearch svg g {
  fill: #ffffff;
  stroke: #ffffff;
}
#searchOverlay #searchform {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}
#searchOverlay #searchform .input-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  margin-right: 2rem;
}
#searchOverlay #searchform .input-group .input-group-button {
  width: 100px;
}
#searchOverlay #searchform .input-group input.input-group-field {
  width: auto;
  padding-left: 0.75rem;
  margin-left: 1rem;
  margin-right: -1rem;
  max-width: 200px;
}
@media (min-width: 400px) {
  #searchOverlay #searchform .input-group input.input-group-field {
    max-width: 600px;
  }
}
@media (min-width: 768px) {
  #searchOverlay #searchform .input-group input.input-group-field {
    min-width: 500px;
  }
}
#searchOverlay #searchform .input-group input.input-group-field::placeholder {
  text-transform: uppercase;
}
#searchOverlay #searchform .input-group .button {
  width: 100px;
  background: #ffffff;
}
@media (min-width: 641px) {
  #searchOverlay #searchform .input-group .button {
    width: 120px;
  }
}

body.search-active #searchOverlay {
  display: flex;
}

#searchResults {
  max-width: 950px;
  margin: 0 auto 4rem;
}
#searchResults .search-result {
  border-bottom: 2px solid #5a5a5a;
  padding: 2rem 0;
}
#searchResults .search-result:last-of-type {
  border: none;
}
#searchResults h2.entry-title {
  text-transform: uppercase;
  color: #5a5a5a;
}

body {
  color: #5a5a5a;
  overflow-x: hidden;
}
body {
  --fluid-body: 14px;
}
@media (min-width: 400px) {
  body {
    --fluid-body: calc(
					14px + 4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  body {
    --fluid-body: 18px;
  }
}
body a {
  text-decoration: none;
}
body p {
  line-height: 1.5;
}

.body-controller {
  z-index: 5;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
}

.page .entry-content {
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
}
.page .entry-content h2,
.page .entry-content h3,
.page .entry-content h4 {
  text-transform: uppercase;
  color: #707070;
  line-height: 1.2;
}

ul.pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
ul.pagination li {
  padding: 0.25rem 0.5rem;
}

.page-id-62 .wp-block-column {
  max-height: 400px;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.page-id-62 .wp-block-cover h4 a {
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.page-id-62 .wp-block-cover h4 a:hover {
  color: #cccccc;
}

.entry-content {
  padding-top: 37px;
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px;
  }
}
.wp-block-media-text.is-style-teaser-element {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  max-height: 400px;
}
.wp-block-media-text.is-style-teaser-element figure {
  max-height: 400px;
}
.wp-block-media-text.is-style-teaser-element .wp-block-media-text__media, .wp-block-media-text.is-style-teaser-element .wp-block-media-text__content {
  grid-column: 1;
  grid-row: 1;
  margin: 0;
  max-height: 400px;
}
.wp-block-media-text.is-style-teaser-element .wp-block-media-text__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  color: #ffffff;
  font-family: montserrat;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
  max-height: 400px;
  width: 100%;
  padding: 0;
}
.wp-block-media-text.is-style-teaser-element .wp-block-media-text__content p {
  max-height: 400px;
  width: 100%;
  font-size: var(--fluid-h4) !important;
  margin: 0;
}
.wp-block-media-text.is-style-teaser-element .wp-block-media-text__content p a {
  display: block;
  width: 100%;
  padding: 200px 1rem;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  max-height: 400px;
}
.wp-block-media-text.is-style-teaser-element .wp-block-media-text__content p a:hover {
  color: #cccccc;
}

.page-id-62 .wp-block-media-text {
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
.page-id-62 .wp-block-media-text figure {
  width: 100%;
  height: 100%;
}
.page-id-62 .wp-block-media-text figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#front-slide-wrapper {
  padding: 0;
}
#front-slide-wrapper .swiper-wrapper .swiper-slide {
  position: relative;
  flex-direction: column;
  min-height: 791px;
}
@media (max-width: 991.98px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide {
    min-height: 620px;
  }
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide {
    min-height: 610px;
  }
}
#front-slide-wrapper .swiper-wrapper .swiper-slide img {
  position: absolute;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
#front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content {
  position: absolute;
  bottom: 28%;
  text-align: center;
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0 0;
}
@media (max-width: 991.98px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content {
    padding: 30px 25px 0;
  }
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content {
    padding: 30px 12px 0;
    bottom: 22%;
  }
}
#front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content h1 {
  color: #ffffff;
  margin-bottom: 21px;
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content h1 {
    margin-bottom: 37px;
  }
}
#front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content h1 span {
  color: #dbc992;
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content h1 span {
    display: inline-block;
  }
}
#front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content a.button-ps {
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px 5px 25px 5px;
  border: 2px solid #dbc992;
  min-width: 180px;
  width: 100%;
  display: inline-block;
  max-width: fit-content;
  padding: 12px 10px;
  color: #dbc992;
  transition: all 0.3s ease;
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content a.button-ps {
    padding: 15px 10px;
  }
}
#front-slide-wrapper .swiper-wrapper .swiper-slide .inner-content a.button-ps:hover {
  color: #5a5a5a;
  background-color: #dbc992;
}
#front-slide-wrapper .swiper-nav-wrapper {
  width: fit-content;
  border: 2px solid #dbc992;
  padding: 5px 8px;
  border-radius: 40px;
  position: absolute;
  bottom: 10%;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  column-gap: 7px;
}
#front-slide-wrapper .swiper-nav-wrapper button {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
@media (max-width: 575px) {
  #front-slide-wrapper .swiper-nav-wrapper button {
    width: 30px;
    height: 30px;
  }
}
#front-slide-wrapper .swiper-nav-wrapper .swiper-right {
  background-image: url("../images/yellow-right.png");
}
#front-slide-wrapper .swiper-nav-wrapper .swiper-left {
  background-image: url("../images/yellow-left.png");
}

.annual-events {
  background-color: #2A3352;
  position: absolute;
  width: 100%;
  left: 0;
  height: 60px;
  overflow: hidden;
}
@media (max-width: 575px) {
  .annual-events {
    padding: 0 24px;
    height: 52px;
  }
}
.annual-events .container {
  position: relative;
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}
.annual-events .container .annual-event-content {
  padding-bottom: 23px;
  padding-left: 20px;
}
@media (max-width: 991.98px) {
  .annual-events .container .annual-event-content {
    padding-left: 0;
  }
}
.annual-events .container .annual-event-content h3 {
  color: #ffffff;
  margin: 0;
  padding: 16px 0;
}
@media (max-width: 575px) {
  .annual-events .container .annual-event-content h3 {
    font-size: 17px;
  }
}
.annual-events .container .annual-event-content p {
  color: #ffffff;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  max-width: 687px;
  width: 100%;
  margin: 0 0 16px;
}
.annual-events .container .annual-event-content a {
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  border-radius: 5px 5px 25px 5px;
  border: 2px solid #ffffff;
  min-width: 180px;
  width: 100%;
  display: inline-block;
  max-width: fit-content;
  padding: 13px 10px 12px;
  color: #ffffff;
  transition: all 0.3s ease;
  text-align: center;
}
.annual-events .container .annual-event-content a:hover {
  background-color: #ffffff;
  color: #5a5a5a;
}
.annual-events .container .right-btn {
  position: absolute;
  top: 16px;
  right: 0;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
@media (max-width: 575px) {
  .annual-events .container .right-btn {
    width: 24px;
    height: 24px;
    top: 13px;
  }
}
.annual-events .container .right-btn::after {
  content: "\f347";
  font-family: dashicons;
  color: #5a5a5a;
  transition: all 0.3s ease;
}

.annual-events.active {
  height: fit-content;
}
.annual-events.active .right-btn::after {
  transform: rotate(180deg);
}

.welcometext {
  padding-top: 142px;
  padding-bottom: 90px;
}
@media (max-width: 575px) {
  .welcometext {
    padding: 142px 24px 57px;
  }
}
.welcometext .container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}
.welcometext .container .row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575px) {
  .welcometext .container .row {
    flex-direction: column-reverse;
    gap: 45px;
  }
}
.welcometext .container .row .col-left {
  width: 45%;
}
@media (max-width: 575px) {
  .welcometext .container .row .col-left {
    width: 100%;
  }
}
.welcometext .container .row .col-left p {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
@media (max-width: 575px) {
  .welcometext .container .row .col-left p {
    margin: 20px 0 26px;
  }
}
.welcometext .container .row .col-right {
  width: 49%;
}
@media (max-width: 575px) {
  .welcometext .container .row .col-right {
    width: 100%;
  }
}
.welcometext .container .row .col-right img {
  width: 100%;
  border-top-left-radius: 80px;
  object-fit: cover;
}

@media (max-width: 575px) {
  .news {
    padding: 0 24px;
  }
}
.news .swiper-container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 575px) {
  .news .swiper-container {
    justify-content: center;
  }
}
.news .swiper-container h2.global-cs {
  margin: 0;
  width: 100%;
}
.news .swiper-container .swiper-wrapper {
  margin: 32px 0;
}
.news .swiper-container .swiper-wrapper .swiper-slide {
  background-color: #ebebeb;
  flex-direction: row;
  border-bottom-right-radius: 80px;
  align-items: inherit;
  justify-content: inherit;
}
@media (max-width: 575px) {
  .news .swiper-container .swiper-wrapper .swiper-slide {
    flex-direction: column;
    border-bottom-right-radius: 50px;
  }
}
.news .swiper-container .swiper-wrapper .swiper-slide .post-thumbnail {
  max-width: 160px;
  width: 100%;
}
@media (max-width: 575px) {
  .news .swiper-container .swiper-wrapper .swiper-slide .post-thumbnail {
    max-width: 100%;
  }
}
.news .swiper-container .swiper-wrapper .swiper-slide .post-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 575px) {
  .news .swiper-container .swiper-wrapper .swiper-slide .post-thumbnail img {
    max-height: 200px;
    object-position: top;
  }
}
.news .swiper-container .swiper-wrapper .swiper-slide .col-right {
  padding: 20px 10px 20px 39px;
}
@media (max-width: 575px) {
  .news .swiper-container .swiper-wrapper .swiper-slide .col-right {
    padding: 34px 50px;
  }
}
.news .swiper-container .swiper-wrapper .swiper-slide .col-right h4 {
  margin: 0;
}
.news .swiper-container .swiper-wrapper .swiper-slide .col-right p {
  max-width: 245px;
  width: 100%;
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin: 10px 0 22px;
}
.news .swiper-container .news-nav-wrapper {
  width: fit-content;
  border: 2px solid #5a5a5a;
  padding: 3px 6px;
  border-radius: 40px;
  column-gap: 7px;
  display: inline-flex;
  margin-right: 10px;
}
.news .swiper-container .news-nav-wrapper button {
  width: 34px;
  height: 34px;
  background-size: cover;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.news .swiper-container .news-nav-wrapper .swiper-right {
  background-image: url("../images/grey-right.png");
}
.news .swiper-container .news-nav-wrapper .swiper-left {
  background-image: url("../images/grey-left.png");
}

.give-cta {
  padding: 90px 0;
}
@media (max-width: 575px) {
  .give-cta {
    padding: 50px 0 74px;
  }
}
.give-cta .container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}
.give-cta .container .row {
  display: flex;
  background-color: #dbc992;
  padding: 0 0 0 57px;
  border-radius: 5px;
  border-bottom-right-radius: 80px;
  overflow: hidden;
  gap: 45px;
}
@media (max-width: 575px) {
  .give-cta .container .row {
    flex-direction: column-reverse;
    padding: 0;
    border-bottom-right-radius: 50px;
  }
}
.give-cta .container .row .col-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 575px) {
  .give-cta .container .row .col-left {
    padding: 0 35px 38px 52px;
  }
}
.give-cta .container .row .col-left h2 {
  font-weight: 700;
  margin: 0;
}
@media (max-width: 575px) {
  .give-cta .container .row .col-left h2 {
    font-size: 35px;
  }
}
.give-cta .container .row .col-left p {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 575px) {
  .give-cta .container .row .col-left p {
    margin: 20px 0 32px;
  }
}
.give-cta .container .row .col-left a {
  font-size: 14px;
  line-height: normal;
  font-weight: 700;
  padding-left: 16px;
  border-radius: 5px 5px 25px 5px;
  min-height: 44px;
  letter-spacing: 0 !important;
  text-transform: none;
  font-family: montserrat, sans-serif !important;
  align-items: center;
  min-width: 166px;
}
.give-cta .container .row .col-left a svg {
  margin-right: 14px;
}
.give-cta .container .row .col-right {
  max-width: 384px;
  width: 100%;
  height: 100%;
}
@media (max-width: 575px) {
  .give-cta .container .row .col-right {
    max-width: 100%;
  }
}
.give-cta .container .row .col-right img {
  height: 100%;
  object-fit: cover;
  min-height: 394px;
}
@media (max-width: 575px) {
  .give-cta .container .row .col-right img {
    min-height: 200px;
  }
}

.explore-community {
  padding-top: 0;
  padding-bottom: 90px;
}
@media (max-width: 575px) {
  .explore-community {
    padding: 0 24px 74px;
  }
}
.explore-community .container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}
.explore-community .container h2.global-cs {
  margin-bottom: 32px;
}
@media (max-width: 575px) {
  .explore-community .container h2.global-cs {
    margin-bottom: 30px;
  }
}
.explore-community .container .row {
  display: flex;
  background-color: #ebebeb;
  overflow: hidden;
}
@media (max-width: 575px) {
  .explore-community .container .row {
    border-top-right-radius: 80px;
    flex-direction: column;
  }
}
.explore-community .container .row .col-left {
  position: relative;
  max-width: 335px;
  width: 100%;
  min-height: 321px;
  background-size: cover;
  background-position: center;
  border-top-right-radius: 80px;
}
@media (max-width: 575px) {
  .explore-community .container .row .col-left {
    max-width: 100%;
  }
}
.explore-community .container .row .col-left span {
  color: #ffffff;
  font-family: montserrat, sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  bottom: -16px;
  left: 17px;
  text-transform: uppercase;
}
.explore-community .container .row .col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 405px;
  width: 100%;
  padding: 20px 20px 20px 75px;
}
@media (max-width: 575px) {
  .explore-community .container .row .col-right {
    padding: 48px 50px;
  }
}
.explore-community .container .row .col-right h3 {
  color: #5a5a5a;
  margin: 0;
}
.explore-community .container .row .col-right p {
  color: #5a5a5a;
  font-family: montserrat, sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
@media (max-width: 575px) {
  .explore-community .container .row .col-right p {
    margin: 20px 0 25px;
  }
}
.explore-community .container .row.even {
  justify-content: space-between;
  margin-top: 25px;
}
@media (max-width: 575px) {
  .explore-community .container .row.even {
    flex-direction: column-reverse;
    border-top-right-radius: 0;
    border-top-left-radius: 80px;
  }
}
.explore-community .container .row.even .col-left {
  border-top-left-radius: 80px;
  border-top-right-radius: 0;
}

@media (max-width: 575px) {
  .events-wrapper {
    padding: 0 24px;
  }
}
.events-wrapper .container {
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
}
.events-wrapper .tribe-common-l-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 58px !important;
}
@media (max-width: 575px) {
  .events-wrapper .tribe-common-l-container {
    padding-bottom: 33px;
  }
}

#instagram-feed-cs {
  background-color: #ebebeb;
  padding-top: 39px;
  padding-bottom: 39px;
}
@media (max-width: 575px) {
  #instagram-feed-cs {
    padding: 41px 24px 22px;
  }
}

section#homeEvents {
  background: #ebebeb;
  padding: 1rem 0 3rem;
  position: relative;
}
section#homeEvents .about-text {
  width: 100%;
  max-width: 905px;
  padding: 1rem 0;
  margin: 0 auto;
}
@media (max-width: 920px) {
  section#homeEvents .about-text {
    padding: 1rem;
  }
}
section#homeEvents .announcement-section h2 {
  padding: 0;
}
section#homeEvents h2 {
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #707070;
  margin-bottom: 2rem;
}
section#homeEvents .swiper-right,
section#homeEvents .swiper-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  z-index: 10;
  cursor: pointer;
}
section#homeEvents .swiper-right {
  right: 0;
  padding-right: 0;
}
section#homeEvents .swiper-right svg {
  right: -9px;
  position: relative;
}
section#homeEvents .swiper-left {
  left: 0;
  padding-left: 0;
}
section#homeEvents .swiper-slide {
  z-index: 1;
}

section#homeActivities {
  max-width: 905px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin: 0 auto;
}
section#homeActivities h2 {
  color: #707070;
  font-family: montserrat;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}
section#homeActivities .activities-grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
section#homeActivities .activities-cta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 334px;
  margin-top: 3rem;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
@media (min-width: 768px) {
  section#homeActivities .activities-cta {
    border-top-left-radius: 95px;
    flex-wrap: nowrap;
    min-height: 350px;
  }
}
section#homeActivities .activities-cta h3 {
  padding-left: 4rem;
  color: #ffffff;
  text-transform: uppercase;
}
section#homeActivities .info {
  border-top-left-radius: 95px;
  background: #dbc992;
  padding: 1.5rem 2.5rem 1.5rem 3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 19px;
  line-height: 1.3;
  width: 100%;
}
@media (min-width: 768px) {
  section#homeActivities .info {
    max-width: 300px;
    width: 100%;
    margin-left: 1rem;
  }
}

section#homeCommunity {
  max-width: 905px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 4rem;
  margin: 0 auto;
}
section#homeCommunity h2 {
  color: #707070;
  font-family: montserrat;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
}
section#homeCommunity .row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
@media (min-width: 639px) {
  section#homeCommunity .row {
    flex-wrap: nowrap;
  }
}
section#homeCommunity .row .left.image, section#homeCommunity .row .right.image {
  min-height: 225px;
}
@media (min-width: 1024px) {
  section#homeCommunity .row .left.image, section#homeCommunity .row .right.image {
    min-height: calc(225px + 6rem);
  }
}
section#homeCommunity .row .image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 252px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 639px) {
  section#homeCommunity .row .image {
    width: 33.3%;
  }
}
section#homeCommunity .row .image h3 {
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  padding-left: 0.5rem;
  line-height: 0.7;
  font-size: 40px;
  font-weight: 700;
}
section#homeCommunity .row .content {
  width: 100%;
  background: #ebebeb;
  padding: 3rem;
}
@media (min-width: 639px) {
  section#homeCommunity .row .content {
    width: 66.6%;
  }
}
section#homeCommunity .row .content .title {
  color: #707070;
  font-weight: 700;
  text-transform: uppercase;
}
section#homeCommunity .row .content .title p {
  line-height: 1.2;
}
section#homeCommunity .row .content.top {
  border-top-right-radius: 95px;
}
section#homeCommunity .row .content.bottom {
  border-bottom-left-radius: 95px;
}
section#homeCommunity .row .right.content.top {
  order: 1;
}
@media (min-width: 639px) {
  section#homeCommunity .row .right.content.top {
    order: 2;
  }
}
section#homeCommunity .row .left.image.top {
  order: 2;
}
@media (min-width: 639px) {
  section#homeCommunity .row .left.image.top {
    order: 1;
  }
}
section#homeCommunity .row .left.content.bottom {
  order: 2;
}
@media (min-width: 639px) {
  section#homeCommunity .row .left.content.bottom {
    order: 1;
  }
}
section#homeCommunity .row .right.image.bottom {
  order: 1;
}
@media (min-width: 639px) {
  section#homeCommunity .row .right.image.bottom {
    order: 2;
  }
}

#visitExplore .row,
#visitWaverly .row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 905px;
  width: 100%;
  margin: 2rem auto;
}
@media (min-width: 639px) {
  #visitExplore .row,
#visitWaverly .row {
    flex-wrap: nowrap;
  }
}
#visitExplore .row .image,
#visitWaverly .row .image {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 276px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-top-left-radius: 95px;
}
@media (min-width: 639px) {
  #visitExplore .row .image,
#visitWaverly .row .image {
    width: 50%;
  }
}
#visitExplore .row .image h2,
#visitWaverly .row .image h2 {
  color: #ffffff;
  text-transform: uppercase;
  margin: 0;
  padding-left: 0.5rem;
  line-height: 0.7;
  font-size: 40px;
  font-weight: 700;
  padding-right: 1rem;
}
#visitExplore .row .content,
#visitWaverly .row .content {
  width: 100%;
  padding: 1rem 3rem;
}
@media (min-width: 639px) {
  #visitExplore .row .content,
#visitWaverly .row .content {
    width: 50%;
  }
}
#visitExplore .row .content .title,
#visitWaverly .row .content .title {
  color: #707070;
  font-weight: 700;
  text-transform: uppercase;
}
#visitExplore .row .content .title p,
#visitWaverly .row .content .title p {
  line-height: 1.2;
}

#visitEventCategories {
  padding: 0;
}
#visitEventCategories .swiper-right,
#visitEventCategories .swiper-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  z-index: 10;
  cursor: pointer;
}
#visitEventCategories .swiper-right {
  right: 0;
}
#visitEventCategories .swiper-left {
  left: 0;
}
#visitEventCategories .swiper-slide {
  z-index: 1;
  min-width: 297px;
}
#visitEventCategories .event-category-card {
  width: 100%;
  min-width: 297px;
}

#visitPlan {
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 3rem;
}
#visitPlan .inner {
  margin: 1rem auto 0;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#visitPlan h2 {
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1;
  width: 100%;
}
#visitPlan h3 {
  text-transform: uppercase;
  line-height: 1;
  color: #ffffff;
  margin-bottom: 1.5rem;
  width: 100%;
}
#visitPlan a {
  color: #ffffff;
  text-transform: uppercase;
  border: 2px solid #dbc992;
  padding: 0.5rem 1.25rem;
  font-size: 16px;
  font-weight: bold;
  margin-right: 1.25rem;
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}
@media (min-width: 641px) {
  #visitPlan a {
    width: auto;
  }
}
#visitPlan iframe {
  width: 100%;
  margin-top: 1rem;
}
#visitPlan hr {
  background-color: #dbc992;
  width: 100%;
  height: 24px;
  border: none;
  margin: 0;
  padding: 0;
}

#visitWaverly .row .image {
  border-top-left-radius: 0;
  border-top-right-radius: 95px;
  min-height: 276px;
  align-items: flex-start;
  margin-top: 2rem;
}
#visitWaverly .row .image h2 {
  padding-left: 1.5rem;
  padding-right: 0;
}

#donate {
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
}
#donate .block-quote {
  background: #334aff;
  border-top-right-radius: 95px;
  color: #ffffff;
  padding: 2.5rem 2.5rem 2rem;
  font-weight: 700;
  line-height: 1.75;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
#donate .block-quote {
  font-size: 16px;
}
@media (min-width: 400px) {
  #donate .block-quote {
    font-size: calc(
					16px + -2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donate .block-quote {
    font-size: 14px;
  }
}
#donate h2,
#donate h3,
#donate h4 {
  text-transform: uppercase;
  color: #707070;
  line-height: 1.2;
}
#donate h2 {
  font-size: 33px;
}
@media (min-width: 400px) {
  #donate h2 {
    font-size: calc(
					33px + -3 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donate h2 {
    font-size: 30px;
  }
}
#donate h3 {
  margin-bottom: 1rem;
}
#donate h3 {
  font-size: 20px;
}
@media (min-width: 400px) {
  #donate h3 {
    font-size: calc(
					20px + -2 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donate h3 {
    font-size: 18px;
  }
}
#donate h4 {
  font-size: 18px;
}
@media (min-width: 400px) {
  #donate h4 {
    font-size: calc(
					18px + -4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donate h4 {
    font-size: 14px;
  }
}
#donate .top-section {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  flex-wrap: wrap;
}
#donate .top-section .left,
#donate .top-section .right {
  width: 100%;
}
@media (min-width: 767px) {
  #donate .top-section .left,
#donate .top-section .right {
    width: 50%;
  }
}
#donate .top-section .left #form,
#donate .top-section .right #form {
  border: 2px solid #707070;
  padding: 2rem;
  max-width: 300px;
  margin-bottom: 2rem;
}
#donate .top-section .left {
  padding-right: 1rem;
}
#donate .top-section .right {
  margin-top: 1.25rem;
}
#donate .top-section .right .block-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
#donate .top-section .right .block-section .block {
  border: 4px solid #334aff;
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 3rem 1rem 3.5rem;
  font-size: 14px;
}
@media (min-width: 768px) {
  #donate .top-section .right .block-section .block {
    width: calc(50% - 1rem);
    margin-right: 0.5rem;
  }
  #donate .top-section .right .block-section .block.top {
    border-top-right-radius: 95px;
  }
  #donate .top-section .right .block-section .block.bottom {
    border-bottom-left-radius: 95px;
  }
}
#donate .top-section .right .block-section .block h4 {
  color: #334aff;
  margin-bottom: 1rem;
}
@media (max-width: 639px) {
  #donate .top-section .right .block-section .block {
    border-top-right-radius: 95px;
  }
  #donate .top-section .right .block-section .top ~ .top {
    border-bottom-left-radius: 95px;
    border-top-right-radius: 0;
  }
  #donate .top-section .right .block-section .bottom ~ .bottom {
    border-bottom-left-radius: 95px;
    border-top-right-radius: 0;
  }
}
#donate .top-section .right img {
  width: 100%;
}
#donate .middle-section {
  margin-top: 2rem;
}
#donate .middle-section .block-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
#donate .middle-section .block-section .block {
  width: 100%;
  margin-bottom: 1rem;
  margin-right: 0;
  padding: 1.5rem 1.5rem 1rem;
  font-size: 14px;
  border-top-right-radius: 95px;
}
@media (min-width: 641px) {
  #donate .middle-section .block-section .block {
    width: calc(50% - 1rem);
    margin-right: 0.5rem;
  }
}
#donate .middle-section .block-section .block h4 {
  color: #334aff;
  margin-bottom: 1rem;
}
#donate .middle-section .block-section .block.even {
  border: 4px solid #a99969;
}
#donate .middle-section .block-section .block.odd {
  border: 4px solid #dbc992;
}
#donate .middle-section .block-section .block a {
  display: inline-block;
  margin-bottom: 1rem;
}
#donate .middle-section .contact-block-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
#donate .middle-section .contact-block-section .contact-block {
  background: #ebebeb;
  padding: 2rem 1rem;
  width: 100%;
  margin-right: 0;
  margin-bottom: 1rem;
}
@media (min-width: 641px) {
  #donate .middle-section .contact-block-section .contact-block {
    width: calc(32.2% - 0.5rem);
    margin-right: 1rem;
  }
}
#donate .middle-section .contact-block-section .contact-block:last-child() {
  margin-right: 0;
}
#donate .middle-section .contact-block-section .contact-block h4 {
  color: #191919;
  margin-bottom: 0.25rem;
}
#donate .middle-section .contact-block-section .contact-block p {
  margin: 0;
}
#donate .middle-section .block-quote {
  max-width: 100%;
}
@media (min-width: 641px) {
  #donate .middle-section .block-quote {
    max-width: 50%;
  }
}
#donate .donors {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}
@media (min-width: 641px) {
  #donate .donors {
    flex-wrap: nowrap;
  }
}
#donate .donors .previousYear,
#donate .donors .nextYear {
  width: 100%;
}
@media (min-width: 641px) {
  #donate .donors .previousYear,
#donate .donors .nextYear {
    width: calc(50% - 1rem);
  }
}
#donate .donors .previousYear h3,
#donate .donors .nextYear h3 {
  color: #191919;
  font-size: 28px;
  background: #ebebeb;
  text-align: center;
  padding: 3rem 2rem 1.5rem;
}
#donate .donors .previousYear .list,
#donate .donors .nextYear .list {
  padding-left: 5.5rem;
  line-height: 2;
}
#donate .donors .previousYear .list p,
#donate .donors .nextYear .list p {
  line-height: 2;
}
#donate .donors .previousYear {
  margin-right: 0;
}
@media (min-width: 641px) {
  #donate .donors .previousYear {
    margin-right: 1rem;
  }
}

#donateInnerTop {
  display: flex;
  flex-direction: row;
  max-width: 905px;
  width: 100%;
  margin: 3rem auto 5rem;
  flex-wrap: wrap;
}
#donateInnerTop h2 {
  text-transform: uppercase;
  color: #5a5a5a;
}
#donateInnerTop .right,
#donateInnerTop .left {
  width: 100%;
}
@media (min-width: 767px) {
  #donateInnerTop .right,
#donateInnerTop .left {
    width: 50%;
  }
}
#donateInnerTop .left {
  padding-right: 2rem;
}
#donateInnerTop .right h3 {
  color: #334aff;
  text-transform: uppercase;
  padding-right: 0;
}
#donateInnerTop .right h3 {
  font-size: 20px;
}
@media (min-width: 400px) {
  #donateInnerTop .right h3 {
    font-size: calc(
					20px + -4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donateInnerTop .right h3 {
    font-size: 16px;
  }
}
@media (min-width: 767px) {
  #donateInnerTop .right h3 {
    padding-left: 2rem;
  }
}
#donateInnerTop .right .content {
  background: #334aff;
  border-top-right-radius: 95px;
  color: #ffffff;
  padding: 2rem;
  font-weight: 700;
  line-height: 1.75;
}
#donateInnerTop .right .content p {
  line-height: 1.75;
}

#donateInnerMiddle {
  display: flex;
  flex-direction: column;
  max-width: 905px;
  width: 100%;
  margin: 0 auto 5rem;
}
#donateInnerMiddle h3 {
  margin-top: 4rem;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 2rem;
  max-width: 171px;
}
#donateInnerMiddle h3 {
  font-size: 20px;
}
@media (min-width: 400px) {
  #donateInnerMiddle h3 {
    font-size: calc(
					20px + -4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donateInnerMiddle h3 {
    font-size: 16px;
  }
}
#donateInnerMiddle .panel {
  color: #334aff;
  background: #ffffff;
  border: 4px solid #334aff;
  border-top-right-radius: 95px;
  padding: 2rem;
  font-weight: 700;
  line-height: 1.75;
  width: 98%;
  max-width: 419px;
}
#donateInnerMiddle .panel p {
  line-height: 1.75;
}

#donateInnerBottom {
  display: flex;
  flex-direction: row;
  max-width: 905px;
  width: 100%;
  margin: 0 auto 5rem;
  flex-wrap: wrap;
}
@media (min-width: 767px) {
  #donateInnerBottom {
    flex-wrap: nowrap;
  }
}
#donateInnerBottom .left,
#donateInnerBottom .right {
  width: 100%;
}
@media (min-width: 767px) {
  #donateInnerBottom .left,
#donateInnerBottom .right {
    width: 50%;
  }
}
#donateInnerBottom .left {
  margin-right: 0;
  margin-bottom: 2rem;
}
@media (min-width: 767px) {
  #donateInnerBottom .left {
    margin-right: 2rem;
  }
}
#donateInnerBottom .right h3 {
  color: #5a5a5a;
  text-transform: uppercase;
}
#donateInnerBottom .right h3 {
  font-size: 20px;
}
@media (min-width: 400px) {
  #donateInnerBottom .right h3 {
    font-size: calc(
					20px + -4 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #donateInnerBottom .right h3 {
    font-size: 16px;
  }
}

#impactTimeline {
  max-width: 905px;
  width: 100%;
  margin: 3rem auto;
}
#impactTimeline .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
@media (min-width: 641px) {
  #impactTimeline .row {
    flex-wrap: nowrap;
  }
}
#impactTimeline .row .image {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 0;
}
@media (min-width: 641px) {
  #impactTimeline .row .image {
    padding-top: 3rem;
    width: calc(50% - 2px);
  }
}
#impactTimeline .row .image img {
  width: calc(100% - 2px);
  border: 1px solid #5a5a5a;
}
#impactTimeline .row .content {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 2rem;
  padding-bottom: 6rem;
}
@media (min-width: 641px) {
  #impactTimeline .row .content {
    width: calc(50% + 2px);
    padding-bottom: 0;
    margin-left: 0;
  }
}
#impactTimeline .row .content .inner {
  max-width: 300px;
  width: 100%;
}
#impactTimeline .row .content h2 {
  color: #5a5a5a;
  text-transform: uppercase;
}
#impactTimeline .row .content h2 {
  font-size: 28px;
}
@media (min-width: 400px) {
  #impactTimeline .row .content h2 {
    font-size: calc(
					28px + 6 *
						((100vw - 400px) / 800)
				);
  }
}
@media (min-width: 1200px) {
  #impactTimeline .row .content h2 {
    font-size: 34px;
  }
}
#impactTimeline .row .content p {
  max-width: 300px;
  font-size: 18px;
}
#impactTimeline .row .content .amount {
  background: #334aff;
  color: #ffffff;
  font-weight: bold;
  font-size: 47px;
  width: auto;
  display: inline-flex;
  font-family: montserrat, sans-serif;
  padding: 0.5rem 1rem;
}
#impactTimeline .row.odd .content {
  border-left: 4px solid #334aff;
  order: 2;
  align-items: flex-start;
  padding-left: 2rem;
}
#impactTimeline .row.odd .content .amount {
  margin-left: -3rem;
}
#impactTimeline .row.odd .image {
  order: 1;
  justify-content: flex-end;
}
#impactTimeline .row.odd .image img {
  margin-right: 0;
}
@media (min-width: 641px) {
  #impactTimeline .row.odd .image img {
    margin-right: 2rem;
  }
}
#impactTimeline .row.even .content {
  order: 2;
  border-left: 4px solid #334aff;
  border-right: 0;
  padding-right: 0;
  align-items: flex-start;
  padding-left: 2rem;
}
#impactTimeline .row.even .content .amount {
  margin-right: 0;
}
@media (min-width: 641px) {
  #impactTimeline .row.even .content {
    align-items: flex-end;
    order: 1;
    border-right: 4px solid #334aff;
    border-left: 0;
    padding-left: 0;
    padding-right: 1rem;
  }
  #impactTimeline .row.even .content .amount {
    margin-right: -2rem;
  }
}
#impactTimeline .row.even .image {
  order: 1;
  justify-content: flex-start;
}
@media (min-width: 641px) {
  #impactTimeline .row.even .image {
    order: 2;
  }
  #impactTimeline .row.even .image img {
    margin-left: 2rem;
  }
}
#impactTimeline .row:first-child .image,
#impactTimeline .row:first-child .content {
  padding-bottom: 0;
  padding-top: 3rem;
}
@media (min-width: 641px) {
  #impactTimeline .row:first-child .image,
#impactTimeline .row:first-child .content {
    padding-top: 0;
  }
}
#impactTimeline .row:first-child .content {
  padding-bottom: 6rem;
}
@media (min-width: 641px) {
  #impactTimeline .row:first-child .content {
    padding-bottom: 0;
  }
}
#impactTimeline .row:last-child .image,
#impactTimeline .row:last-child .content {
  padding-bottom: 0;
}
@media (min-width: 641px) {
  #impactTimeline .row:last-child .image,
#impactTimeline .row:last-child .content {
    padding-bottom: 6rem;
  }
}

.error404 .inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 905px;
}
.error404 .inner-container h1 {
  margin-bottom: 2rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 35px;
}
.error404 .inner-container .content {
  background: #ffffff;
  border-top-right-radius: 95px;
  max-width: 510px;
  padding: 2rem 5.25rem 4rem 2rem;
}
.error404 .inner-container .content h2 {
  text-transform: uppercase;
  color: #707070;
}
.error404 .inner-container .content a {
  margin-top: 1.5rem;
  display: inline-block;
}