/**
 * Can be used in various places like fluid typography
 * Must be called from styles ex: padding: var(--fluid-padding)
 * Very useful when styles looks too large on mobile
 **/
@mixin responsive-sizing() {
	:root {
		@include fluid-prop(--fluid-padding, $padding-sm, $padding);
		@include fluid-prop(--fluid-padding-md, $padding, $padding-md);
		@include fluid-prop(--fluid-padding-lg, $padding-md, $padding-lg);

		@include fluid-prop(--fluid-container-padding-tb, $padding-c-tb, $padding-c-tb-sm);
	}
}
