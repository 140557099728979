#donate {
	max-width: $global-max-width;
	width: 100%;
	margin: 0 auto;

	.block-quote {
		background: $blue;
		border-top-right-radius: 95px;
		color: $white;
		padding: 2.5rem 2.5rem 2rem;
		@include fluid-prop(font-size, 16px, 14px);
		font-weight: 700;
		line-height: 1.75;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}

	h2,
	h3,
	h4 {
		text-transform: uppercase;
		color: $med-gray;
		line-height: 1.2;
	}
	h2 {
		@include fluid-prop(font-size, 33px, 30px);
	}
	h3 {
		@include fluid-prop(font-size, 20px, 18px);
		margin-bottom: 1rem;
	}
	h4 {
		@include fluid-prop(font-size, 18px, 14px);
	}
	.top-section {
		display: flex;
		flex-direction: row;
		margin-top: 1rem;
		flex-wrap: wrap;

		.left,
		.right {
			width: 100%;

			@include breakpoint(767px) {
				width: 50%;
			}

			#form {
				border: 2px solid $med-gray;
				padding: 2rem;
				max-width: 300px;
				margin-bottom: 2rem;
			}
		}
		.left {
			padding-right: 1rem;
		}
		.right {
			margin-top: 1.25rem;

			.block-section {
				display: flex;
				flex-direction: row;
				justify-content: center;
				flex-wrap: wrap;

				.block {
					border: 4px solid $blue;
					width: 100%;
					margin-bottom: 0.5rem;
					padding: 3rem 1rem 3.5rem;
					font-size: 14px;

					@include breakpoint(medium) {
						width: calc(50% - 1rem);
						margin-right: 0.5rem;
						&.top {
							border-top-right-radius: 95px;
						}
						&.bottom {
							border-bottom-left-radius: 95px;
						}
					}

					h4 {
						color: $blue;
						margin-bottom: 1rem;
					}
				}
				@include breakpoint(639px, down) {
					.block {
						border-top-right-radius: 95px;

					}
					.top ~ .top {
						border-bottom-left-radius: 95px;
						border-top-right-radius: 0;
					}
					.bottom ~ .bottom {
						border-bottom-left-radius: 95px;
						border-top-right-radius: 0;
					}

				}
			}
			img {
				width: 100%;
			}
		}
	}

	.middle-section {
		margin-top: 2rem;

		.block-section {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 2rem;

			.block {
				width: 100%;
				margin-bottom: 1rem;
				margin-right: 0;
				padding: 1.5rem 1.5rem 1rem;
				font-size: 14px;
				border-top-right-radius: 95px;

				@include breakpoint(641px) {
					width: calc(50% - 1rem);
					margin-right: 0.5rem;
				}
				h4 {
					color: $blue;
					margin-bottom: 1rem;
				}
				&.even {
					border: 4px solid $dark-tan;
				}
				&.odd {
					border: 4px solid $light-tan;
				}
				a {
					display: inline-block;
					margin-bottom: 1rem;
				}
			}
		}
		.contact-block-section {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-wrap: wrap;
			margin-bottom: 2rem;

			.contact-block {
				background: $light-gray;
				padding: 2rem 1rem;
				width: 100%;
				margin-right: 0;
				margin-bottom: 1rem;

				@include breakpoint(641px) {
					width: calc(32.2% - 0.5rem);
					margin-right: 1rem;
				}
				&:last-child() {
					margin-right: 0;
				}

				h4 {
					color: $black;
					margin-bottom: 0.25rem;
				}
				p {
					margin: 0;
				}
			}
		}
		.block-quote {
			max-width: 100%;

			@include breakpoint(641px) {
				max-width: 50%;
			}
		}
	}

	.donors {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		margin-bottom: 2rem;
		flex-wrap: wrap;

		@include breakpoint(641px) {
			flex-wrap: nowrap;
		}
		.previousYear,
		.nextYear {
			width: 100%;

			@include breakpoint(641px) {
				width: calc(50% - 1rem);
			}
			h3 {
				color: $black;
				font-size: 28px;
				background: $light-gray;
				text-align: center;
				padding: 3rem 2rem 1.5rem;
			}
			.list {
				padding-left: 5.5rem;
				line-height: 2;
				p {
					line-height: 2;
				}
			}
		}
		.previousYear {
			margin-right: 0;

			@include breakpoint(641px) {
				margin-right: 1rem;
			}
		}
	}
}

#donateInnerTop {
	display: flex;
	flex-direction: row;
	max-width: $global-max-width;
	width: 100%;
	margin: 3rem auto 5rem;
	flex-wrap: wrap;

	h2 {
		text-transform: uppercase;
		color: $gray;
	}

	.right,
	.left {
		width: 100%;

		@include breakpoint(767px) {
			width: 50%;
		}
	}

	.left {
		padding-right: 2rem;
	}
	.right {
		h3 {
			color: $blue;
			text-transform: uppercase;
			@include fluid-prop(font-size, 20px, 16px);
			padding-right: 0;

			@include breakpoint(767px) {
				padding-left: 2rem;
			}
		}
		.content {
			background: $blue;
			border-top-right-radius: 95px;
			color: $white;
			padding: 2rem;
			font-weight: 700;
			line-height: 1.75;
			p {
				line-height: 1.75;
			}
		}
	}
}
#donateInnerMiddle {
	display: flex;
	flex-direction: column;
	max-width: $global-max-width;
	width: 100%;
	margin: 0 auto 5rem;

	h3 {
		margin-top: 4rem;
		color: $white;
		text-transform: uppercase;
		padding-left: 2rem;
		max-width: 171px;
		@include fluid-prop(font-size, 20px, 16px);
	}
	.panel {
		color: $blue;
		background: $white;
		border: 4px solid $blue;
		border-top-right-radius: 95px;
		padding: 2rem;
		font-weight: 700;
		line-height: 1.75;
		width: 98%;
		max-width: 419px;
		p {
			line-height: 1.75;
		}
	}
}

#donateInnerBottom {
	display: flex;
	flex-direction: row;
	max-width: $global-max-width;
	width: 100%;
	margin: 0 auto 5rem;
	flex-wrap: wrap;
	@include breakpoint(767px) {
		flex-wrap: nowrap;
	}

	.left,
	.right {
		width: 100%;

		@include breakpoint(767px) {
			width: 50%;
		}
	}
	.left {
		margin-right: 0;
		margin-bottom: 2rem;

		@include breakpoint(767px) {
			margin-right: 2rem;
		}
	}
	.right {
		h3 {
			color: $gray;
			text-transform: uppercase;
			@include fluid-prop(font-size, 20px, 16px);
		}
	}
}
