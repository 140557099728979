$breakpoints: (
  xsmall: 320px,
  small: 480px,
  medium: 768px,
  large: 1024px,
  nav: 1024px,
  xlarge: $global-width,
);

// Custom breakpoints
@mixin xbreakpoint($point) {
	@if $point == xs {
	  @media (max-width: 380px) {
		@content;
	  }
	} @else if $point == sm {
		@media (max-width: 575px) {
		  @content;
		}
	  }@else if $point == md {
	  @media (max-width: 767.98px) {
		@content;
	  }
	} @else if $point == lg {
	  @media (max-width: 991.98px) {
		@content;
	  }
	} @else if $point == xl {
		@media (max-width: 1023.98px) {
		  @content;
		}
	} @else if $point == xxl {
	  @media (max-width: 1199px) {
		@content;
	  }
	} @else if $point == xxxl {
	  @media (max-width: 1399px) {
		@content;
	  }
	}
  }