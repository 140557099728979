#front-slide-wrapper{
	padding: 0;
	.swiper-wrapper{
		.swiper-slide{
			position: relative;
			flex-direction: column;
			min-height: 791px;
			@include xbreakpoint(lg){
				min-height: 620px;
			}
			@include xbreakpoint(sm){
				min-height: 610px;
			}
			img{
				position: absolute;
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
			.inner-content{
				position: absolute;
				bottom: 28%;
				text-align: center;
				max-width: 720px;
				width: 100%;
				margin: 0 auto;
				padding: 30px 0 0;
				@include xbreakpoint(lg){
					padding: 30px 25px 0;
				}
				@include xbreakpoint(sm){
					padding: 30px 12px 0;
					bottom: 22%;
				}
				h1{
					color: $white;
					margin-bottom: 21px;
					@include xbreakpoint(sm){
						margin-bottom: 37px;
					}
					span{
						color: $light-tan;
						@include xbreakpoint(sm){
							display: inline-block;
						}
					}
				}
				a.button-ps{
					font-family: $header-font-family;
					font-size: 14px;
					font-weight: 700;
					line-height: normal;
					border-radius: 5px 5px 25px 5px;
					border: 2px solid $light-tan;
					min-width: 180px;
					width: 100%;
					display: inline-block;
					max-width: fit-content;
					padding: 12px 10px;
					color: $light-tan;
					transition: all .3s ease;
					@include xbreakpoint(sm){
						padding: 15px 10px;
					}
					&:hover{
						color: $gray;
						background-color: $light-tan;
					}
				}
			}
		}
	}
	.swiper-nav-wrapper{
		width: fit-content;
		border: 2px solid #dbc992;
		padding: 5px 8px;
		border-radius: 40px;
		position: absolute;
		bottom: 10%;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		column-gap: 7px;
		button{
			width: 36px;
			height: 36px;
			background-size: cover;
			background-color: transparent;
			border: none;
			padding: 0;
			cursor: pointer;
			@include xbreakpoint(sm){
				width: 30px;
				height: 30px;
			}
		}
		.swiper-right{
			background-image: url('../images/yellow-right.png');
		}
		.swiper-left{
			background-image: url('../images/yellow-left.png');
		}
	}
}
// annual events
.annual-events{
	background-color: $dark-blue;
	position: absolute;
	width: 100%;
	left: 0;
	height: 60px;
	overflow: hidden;
	@include xbreakpoint(sm){
		padding: 0 24px;
		height: 52px;
	}
	.container{
		position: relative;
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		.annual-event-content{
			padding-bottom: 23px;
			padding-left: 20px;
			@include xbreakpoint(lg){
				padding-left: 0;
			}
			h3{
				color: $white;
				margin: 0;
    			padding: 16px 0;
				@include xbreakpoint(sm){
					font-size: 17px;
				}
			}
			p{
				color: $white;
				font-family: $header-font-family;
				font-size: 14px;
				font-weight: 600;
				line-height: 22px;
				max-width: 687px;
				width: 100%;
				margin: 0 0 16px;
			}
			a{
				font-family: $header-font-family;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				border-radius: 5px 5px 25px 5px;
				border: 2px solid $white;
				min-width: 180px;
				width: 100%;
				display: inline-block;
				max-width: fit-content;
				padding: 13px 10px 12px;
				color: $white;
				transition: all 0.3s ease;
				text-align: center;
				&:hover{
					background-color: $white;
					color: $gray;
				}
			}
		}
		.right-btn{
			position: absolute;
			top: 16px;
			right: 0;
			width: 28px;
			height: 28px;
			background-color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			@include xbreakpoint(sm){
				width: 24px;
				height: 24px;
				top: 13px;
			}
			&::after{
				content: "";
				font-family: dashicons;
				color: $gray;
				transition: all .3s ease;
			}
		}
	}
}
.annual-events.active{
	height: fit-content;
	.right-btn{
		&::after{
			transform: rotate(180deg);
		}
	}
}
// welcome comm
.welcometext{
	padding-top: 142px;
	padding-bottom: 90px;
	@include xbreakpoint(sm){
		padding: 142px 24px 57px;
	}
	.container{
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		.row{
			display: flex;
			flex-direction: row;
			gap: 15px;
			justify-content: space-between;
			align-items: center;
			@include xbreakpoint(sm){
				flex-direction: column-reverse;
				gap: 45px;
			}
			.col-left{
				width: 45%;
				@include xbreakpoint(sm){
					width: 100%;
				}
				p{
				color: $gray;
				font-family: $header-font-family;
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				line-height: 22px;
				@include xbreakpoint(sm){
					margin: 20px 0 26px;
				}
				}
			}
			.col-right{
				width: 49%;
				@include xbreakpoint(sm){
					width: 100%;
				}
				img{
					width: 100%;
					border-top-left-radius: 80px;
					object-fit: cover;
				}
			}
		}
	}
}
// news
.news{
	@include xbreakpoint(sm){
		padding: 0 24px;
	}
	.swiper-container{
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		@include xbreakpoint(sm){
			justify-content: center;
		}
		h2.global-cs{
			margin: 0;
			width: 100%;
		}
		.swiper-wrapper{
			margin: 32px 0;
			.swiper-slide{
				background-color: $light-gray;
				flex-direction: row;
				border-bottom-right-radius: 80px;
				align-items: inherit;
				justify-content: inherit;
				@include xbreakpoint(sm){
					flex-direction: column;
					border-bottom-right-radius: 50px;
				}
				.post-thumbnail{
					max-width: 160px;
					width: 100%;
					@include xbreakpoint(sm){
						max-width: 100%;
					}
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
						@include xbreakpoint(sm){
							max-height: 200px;
							object-position: top;
						}
					}
				}
				.col-right{
					padding: 20px 10px 20px 39px;
					@include xbreakpoint(sm){
						padding: 34px 50px;
					}
					h4{
						margin: 0;
					}
					p{
						max-width: 245px;
						width: 100%;
						color: $gray;
						font-family: $header-font-family;
						font-size: 14px;
						font-weight: 600;
						line-height: 22px;
						margin: 10px 0 22px;
					}
				}
			}
		}
		.news-nav-wrapper{
			width: fit-content;
			border: 2px solid $gray;
			padding: 3px 6px;
			border-radius: 40px;
			column-gap: 7px;
			display: inline-flex;
			margin-right: 10px;
			button{
				width: 34px;
				height: 34px;
				background-size: cover;
				background-color: transparent;
				border: none;
				padding: 0;
				cursor: pointer;
			}
			.swiper-right{
				background-image: url('../images/grey-right.png');
			}
			.swiper-left{
				background-image: url('../images/grey-left.png');
			}
		}
	}
}
// give-cta
.give-cta{
	padding: 90px 0;
	@include xbreakpoint(sm){
		padding: 50px 0 74px;
	}
	.container{
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		.row{
			display: flex;
			background-color: $light-tan;
			padding: 0 0 0 57px;
			border-radius: 5px;
			border-bottom-right-radius: 80px;
			overflow: hidden;
			gap: 45px;
			@include xbreakpoint(sm){
				flex-direction: column-reverse;
				padding: 0;
				border-bottom-right-radius: 50px;
			}
			.col-left{
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				@include xbreakpoint(sm){
					padding: 0 35px 38px 52px;
				}
				h2{
					font-weight: 700;
					margin: 0;
					@include xbreakpoint(sm){
						font-size: 35px;
					}
				}
				p{
					color: $gray;
					font-family: $header-font-family;
					font-size: 24px;
					font-weight: 500;
					line-height: normal;
					@include xbreakpoint(sm){
						margin: 20px 0 32px;
					}
				}
				a{
					font-size: 14px;
					line-height: normal;
					font-weight: 700;
					padding-left: 16px;
					border-radius: 5px 5px 25px 5px;
					min-height: 44px;
					letter-spacing: 0 !important;
					text-transform: none;
					font-family: $header-font-family !important;
					align-items: center;
					min-width: 166px;
					svg{
						margin-right: 14px;
					}
				}
			}
			.col-right{
				max-width: 384px;
				width: 100%;
				height: 100%;
				@include xbreakpoint(sm){
					max-width: 100%;
				}
				img{
					height: 100%;
					object-fit: cover;
					min-height: 394px;
					@include xbreakpoint(sm){
						min-height: 200px;
					}
				}
			}
		}
	}
}
// explore-community
.explore-community{
	padding-top: 0;
	padding-bottom: 90px;
	@include xbreakpoint(sm){
		padding: 0 24px 74px;
	}
	.container{
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		h2.global-cs{
			margin-bottom: 32px;
			@include xbreakpoint(sm){
				margin-bottom: 30px;
			}
		}
		.row{
			display: flex;
			background-color: $light-gray;
			overflow: hidden;
			@include xbreakpoint(sm){
				border-top-right-radius: 80px;
				flex-direction: column;
			}
			.col-left{
				position: relative;
				max-width: 335px;
				width: 100%;
				min-height: 321px;
				background-size: cover;
				background-position: center;
				border-top-right-radius: 80px;
				@include xbreakpoint(sm){
					max-width: 100%;
				}
				span{
					color: $white;
					font-family: $header-font-family;
					font-size: 50px;
					font-weight: 700;
					line-height: normal;
					position: absolute;
					bottom: -16px;
					left: 17px;
					text-transform: uppercase;
				}
			}
			.col-right{
				display: flex;
				flex-direction: column;
				justify-content: center;
				max-width: 405px;
				width: 100%;
				padding: 20px 20px 20px 75px;
				@include xbreakpoint(sm){
					padding: 48px 50px;
				}
				h3{
					color: $gray;
					margin: 0;
				}
				p{
					color: $gray;
					font-family: $header-font-family;
					font-size: 14px;
					line-height: 22px;
					font-weight: 600;
					@include xbreakpoint(sm){
						margin: 20px 0 25px;
					}
				}
			}
		}
		.row.even{
			justify-content: space-between;
			margin-top: 25px;
			@include xbreakpoint(sm){
				flex-direction: column-reverse;
				border-top-right-radius: 0;
				border-top-left-radius: 80px;
			}
			.col-left{
				border-top-left-radius: 80px;
				border-top-right-radius: 0;	
			}
		}
	}
}
// events calendar
.events-wrapper{
	@include xbreakpoint(sm){
		padding: 0 24px;
	}
	.container{
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
	}
	.tribe-common-l-container {
		padding-left: 0 !important;
		padding-right: 0 !important;
		padding-bottom: 58px !important;
		@include xbreakpoint(sm){
			padding-bottom: 33px;
		}
	}
}

// instagram feed
#instagram-feed-cs{
	background-color: $light-gray;
	padding-top: 39px;
	padding-bottom: 39px;
	@include xbreakpoint(sm){
		padding: 41px 24px 22px;
	}
}






section#homeEvents {
	background: $light-gray;
	padding: 1rem 0 3rem;
	position: relative;

	.about-text {
		width: 100%;
		max-width: 905px;
		padding: 1rem 0;
		margin: 0 auto;

		@include breakpoint(920px, down) {
			padding: 1rem;
		}
	}

	.announcement-section h2 {
		padding: 0;
	}

	h2 {
		max-width: $global-max-width;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		color: $med-gray;
		margin-bottom: 2rem;
	}
	.swiper-right,
	.swiper-left {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		z-index: 10;
		cursor: pointer;
	}

	.swiper-right {
		right: 0;
		padding-right: 0;

		svg {
			right: -9px;
			position: relative;
		}
	}
	.swiper-left {
		left: 0;
		padding-left: 0;
	}
	.swiper-slide {
		z-index: 1;
	}
}

section#homeActivities {
	max-width: $global-max-width;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 4rem;
	margin: 0 auto;

	h2 {
		color: $med-gray;
		font-family: $header-font-name;
		text-transform: uppercase;
		padding-bottom: 0.5rem;
	}

	.activities-grid {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
	.activities-cta {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;
		min-height: 334px;
		margin-top: 3rem;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;

		@include breakpoint(768px) {
			border-top-left-radius: 95px;
			flex-wrap: nowrap;
			min-height: 350px;
		}

		h3 {
			padding-left: 4rem;
			color: $white;
			text-transform: uppercase;
		}
	}

	.info {
		border-top-left-radius: 95px;
		background: $light-tan;
		padding: 1.5rem 2.5rem 1.5rem 3rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 19px;
		line-height: 1.3;
		width: 100%;

		@include breakpoint(768px) {
			max-width: 300px;
			width: 100%;
			margin-left: 1rem;
		}
	}
}

section#homeCommunity {
	max-width: $global-max-width;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 4rem;
	margin: 0 auto;

	h2 {
		color: $med-gray;
		font-family: $header-font-name;
		text-transform: uppercase;
		padding-bottom: 0.5rem;
	}

	.row {
		display: flex;
		flex-direction: row;
		margin-bottom: 2rem;
		flex-wrap: wrap;

		@include breakpoint(639px) {
			flex-wrap: nowrap;
		}

		.left.image, .right.image {
			min-height: 225px;

			@include breakpoint(large) {
				min-height: calc(225px + 6rem);
			}
		}

		.image {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			min-height: 252px;
			height: 100%;
			background-repeat: no-repeat;
			background-size: cover;

			@include breakpoint(639px) {
				width: 33.3%;
			}

			h3 {
				color: $white;
				text-transform: uppercase;
				margin: 0;
				padding-left: 0.5rem;
				line-height: 0.7;
				font-size: 40px;
				font-weight: 700;
			}
		}
		.content {
			width: 100%;
			background: $light-gray;
			padding: 3rem;
			@include breakpoint(639px) {
				width: 66.6%;
			}
			.title {
				color: $med-gray;
				font-weight: 700;
				text-transform: uppercase;

				p {
					line-height: 1.2;
				}
			}

			&.top {
				border-top-right-radius: 95px;
			}
			&.bottom {
				border-bottom-left-radius: 95px;
			}
		}
		.right.content.top {
			order: 1;

			@include breakpoint(639px) {
				order: 2;
			}
		}
		.left.image.top {
			order: 2;

			@include breakpoint(639px) {
				order: 1;
			}
		}
		.left.content.bottom {
			order: 2;

			@include breakpoint(639px) {
				order: 1;
			}
		}
		.right.image.bottom {
			order: 1;

			@include breakpoint(639px) {
				order: 2;
			}
		}
	}
}
