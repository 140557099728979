footer#footer {
	background: $light-tan;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 15px;
	@include xbreakpoint(md){
		padding: 16px 24px 32px;
	}

	.inner {
		max-width: 880px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		@include breakpoint(medium) {
			flex-wrap: nowrap;
		}
		.contact-left{
			.button.donate{
				display: none;
				@include xbreakpoint(md){
					display: flex;
					align-items: center;
					margin: 23px 0 36px;
				}
			}
			p.phone{
				display: none;
				text-transform: uppercase;
				color: $gray;
				margin-bottom: 9px;
				margin-top: 0;
				font-family: $header-font-family;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.7px;
				width: 100%;
				a{
					color: $gray;
					font-family: $header-font-family;
					font-size: 14px;
					font-weight: 500;
					line-height: normal;
					transition: all 0.2s ease;
				}
				@include xbreakpoint(md){
					display: block;
					padding-left: 12px;
				}
			}
		}
		.contact-left,
		.contact-right {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: flex-start;
			order: 1;
			flex-wrap: wrap;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
		}
		.contact-right {
			justify-content: flex-start;
			align-items: start;
			font-size: 18px;
			padding-top: 16px;
			.socials{
				display: none;
				@include xbreakpoint(md){
					display: flex;
				}
			}

			.phone {
				text-transform: uppercase;
				color: $gray;
				margin-bottom: 16px;
				margin-top: 0;
				font-family: $header-font-family;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.7px;
				text-transform: uppercase;

				a {
					color: $gray;
					font-family: $header-font-family;
					font-size: 14px;
					font-weight: 500;
					line-height: normal;
					transition: all .2s ease;
					&:hover{
						color: $dark-blue;
					}
				}
			}
			address {
				color: $gray;
				font-family: $header-font-family;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				margin-bottom: 16px;
				@include xbreakpoint(md){
					display: none;
				}
			}
			a.button {
				min-width: 155px;
			}
		}
		#footerLogo {
			width: 100%;
			max-width: 134px;
		}

		@include breakpoint(767px, down) {
			.contact-left {
				margin-bottom: 0;
			}
			.contact-right {
				width: 50%;
				order: 3;
				display: none;
			}
		}
		.socials {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin: 17px 0 0;
			@include xbreakpoint(md){
				width: 100%;
				margin: 0;
				justify-content: start;
				padding-left: 12px;
			}

			a.social {
				margin-right: 14px;
				height: 22px;

				&:last-child {
					margin-right: 0;
				}

				svg {
					path {
						fill: $gray;
					}
				}
			}
		}
	}
	.email {
		width: 100%;
		margin-top: 2rem;
		order: 3;
		@include xbreakpoint(md){
			margin-top: 10px;
			padding: 0 12px;
		}

		@include breakpoint(medium) {
			margin-top: 0;
			width: auto;
			order: 2;
			padding-top: 16px;
		}
		h4 {
			color: $gray;
			font-family: $header-font-family;
			font-size: 14px;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.7px;
			text-transform: uppercase;
			margin: 0;
		}
		p {
			color: $gray;
			font-family: $header-font-family;
			font-size: 12px;
			font-weight: 500;
			line-height: normal;
			margin: 4px 0 10px;
		}
		.button.donate{
			min-height: 44px;
			max-width: 166px;
			padding-left: 20px;
			justify-content: start;
			padding-top: 12px;
			margin-bottom: 28px;
			@include xbreakpoint(md){
				display: none;
			}
		}
	}
	.menu {
		order: 1;
		margin: 0;
		margin-right: 45px;
		padding-top: 16px;
		address{
			display: none;
			color: $gray;
			font-family: $header-font-family;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin-bottom: 9px;
			@include xbreakpoint(md){
				display: block;
			}
		}

		@include breakpoint(767px, down) {
			margin-top: 0;
			order: 2;
			width: 58%;
			justify-content: center;
			padding-left: 2rem;
			margin-right: 0;

			nav {
				padding-left: 0;
				margin-bottom: 60px;

				ul {
					width: 100%;
				}
			}
		}

		li {
			margin-bottom: 18px;
			font-size: 14px;
			a {
				color: $gray;
				font-family: $header-font-family;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.7px;
				text-transform: uppercase;
				transition: all .2s ease;
				&:hover{
					color: $dark-blue;
				}
			}
		}
		ul{
			padding: 0 !important;
		}
	}
}
#footer{
	#mc_embed_signup {
		#mc_embed_signup_scroll{
			display: flex;
			flex-direction: row;
			align-items: center;
			@include xbreakpoint(md){
				flex-direction: column;
			}
		}
		.mc-field-group{
			@include xbreakpoint(md){
				width: 100%;
			}
		}
		#mce-EMAIL {
			outline: none;
			padding: 0.5rem 0.5rem;
			width: 100%;
			color: $gray;
			font-family: $header-font-family;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.6px;
			text-transform: uppercase;
			border: none;
			background: rgba(255, 255, 255, 0.50);
			min-height: 36px;
			text-align: center;
			max-width: 154px;
			@include xbreakpoint(md){
				max-width: 100%;
				text-align: start;
				padding-left: 20px;
				margin: 0;
			}
		}
		.optionalParent{
			@include xbreakpoint(md){
				width: 100%;
			}
		}
		input#mc-embedded-subscribe {
			cursor: pointer;
			text-transform: capitalize;
			color: $gray;
			text-align: center;
			font-family: $header-font-family !important;
			font-size: 12px;
			font-weight: 700;
			line-height: normal;
			border-radius: 5px 5px 20px 5px;
			border: 2px solid $gray;
			padding: 11px 19px 9px;
			margin-left: 6px;
			transition: all .3s ease;
			@include xbreakpoint(md){
				max-width: 100%;
				width: 100%;
				margin: 3px 0 0 !important;
				min-height: 40px;
			}
			&:hover{
				background-color: $gray;
				color: $white;
			}
		}
	}
}

// footer bottom
#footerBottom {
	background: $mongoose;
	width: 100%;

	.inner {
		max-width: 610px;
		margin: 0 auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
		display: flex;
		justify-content: start;
		text-align: center;
		flex-direction: column;
		@include xbreakpoint(md){
			padding: 22px 0;
		}

		@include breakpoint(medium) {
			flex-direction: row;
		}
		p {
			color: $white;
			font-family: $header-font-family;
			font-size: 12px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			margin: 0;
			&.terms {
				margin-left: 1rem;

				@include breakpoint(767px, down) {
					span {
						display: none;
					}
				}
			}

			a {
				text-decoration: underline;
				color: $white;
				&:hover{
					color: $dark-blue;
				}
			}
		}
	}
}
