.featured-hero {
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	h1 {
		color: $white;
		max-width: $global-max-width;
		margin: 0 auto;
		width: 100%;
		padding-bottom: 0.5rem;
		line-height: 1.2;
	}
}
